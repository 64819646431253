// import Sidebar from "components/user/Sidebar";
// import ToolBar from "components/user/ToolBar";
// import Listing from "./Listing";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useEffect } from "react";
// import { useAppDispatch } from "hooks/store";
// import { fetchInitialData } from "store/slices/main.slice";

// type Props = {};

// const MainPage = (props: Props) => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   const [searchParams] = useSearchParams();

//   useEffect(() => {
//     if (!searchParams.get("product")) {
//       dispatch(fetchInitialData(navigate));
//     }
//   }, [searchParams]);
//   return (
//     <div className="min-h-screen">
//       <ToolBar />
//       <div className="flex h-[calc(100vh-60px)] relative">
//         <Sidebar />
//         <div className="flex-1">
//           <Listing />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MainPage;

import { useState } from "react"; // Import useState for managing the toggle state
import Sidebar from "components/user/Sidebar";
import ToolBar from "components/user/ToolBar";
import Listing from "./Listing";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { fetchInitialData } from "store/slices/main.slice";
import HorizontalMenu from "components/user/HorizontalBar";

type Props = {};

const MainPage = (props: Props) => {
  const [isSidebar, setIsSidebar] = useState(true); // State to manage sidebar or horizontal menu
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { settings, companyData } = useAppSelector(({ main }) => main);

  const [searchParams] = useSearchParams();
console.log("settings?.menuDirection",settings?.menuDirection);

  useEffect(() => {
    
    if (!searchParams.get("product")) {
      dispatch(fetchInitialData(navigate));
    }
  }, [searchParams, dispatch, navigate]);

  return (
    <div className="min-h-screen">
      <ToolBar />
      <div className="flex flex-col h-[calc(100vh-60px)]">
       
        {settings?.menuDirection === "Left" ? (
          <div className="flex h-full">
            <Sidebar />
            <div className="flex-1 overflow-auto">
              <Listing />
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <HorizontalMenu />
            <div className="flex-1 overflow-auto">
              <Listing />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainPage;
