// import noImage from "assets/images/no-image.png";
// import Button from "components/common/Button";
// import { baseURL } from "config/api";

// type Props = any;

// const Product = (props: Props) => {
//   return (
//     <div
//       className="bg-white rounded-lg p-3 h-full flex max-sm:flex-col gap-2 shadow-[rgba(0,_0,_0,_0.24)_0px_1px_3px]"
//       onClick={props.onClick}
//     >
//       <div className="flex-1 self-center">
//         <img
//           src={props.imageUrl || noImage}
//           className="max-h-[100px] aspect-square sm:max-h-[200px]"
//           onError={(e: any) => {
//             // e.target.src = noImage; // set the fallback image
//           }}
//         />
//       </div>
//       <div className="flex-[2] flex flex-col">
//         <h4 className="font-bold text-[14px]">{props.productName}</h4>
//         <p className="text-[11px] capitalize break-words">
//           {props.productDescription.toLowerCase()}
//         </p>
//         {!!props.price && (
//           <p className="text-[13px] mt-2">
//             <b>Price : </b>
//             {props.price}
//           </p>
//         )}
//         <p className="text-[11px] capitalize bg-primary/10 p-2 rounded-lg mt-1">
//           {props.pricedesc && props.pricedesc !== "0"
//             ? props.pricedesc.toLowerCase()
//             : `$${props.price}`}
//         </p>
//         <div className="flex-1" />
//         <Button className="mt-2 self-end">{props.btnText}</Button>
//       </div>
//     </div>
//   );
// };

// export default Product;

import noImage from "assets/images/no-image.png";
import Button from "components/common/Button";
import { baseURL } from "config/api";

type Props = any;

const Product = (props: Props) => {
  return (
    <div
      className="bg-white shadow-md max-w-lg overflow-hidden border border-gray-200 rounded-3xl "
      onClick={props.onClick}
    >
      <div className="flex flex-col md:flex-row p-2">
        {/* Left side - Image Section */}
        <div className="flex-1 self-center">
          <img
            src={props.imageUrl || noImage}
            className="max-h-[200px] aspect-square rounded-3xl border-4 border-gray-200 object-cover"
            onError={(e) => {
              e.currentTarget.src = noImage;
            }}
          />
        </div>

        {/* Right side - Details */}
        <div className="w-full md:w-2/3 pl-4 flex flex-col justify-center">
          {/* Title and Description */}
          <div>
            <h3 className="font-bold text-lg md:text-base mb-1">
              {props.productName}
            </h3>
            <p className="text-sm text-gray-700 mb-2 break-words">
              {props.productDescription.toLowerCase()}
            </p>

            {/* Add-ons Section */}
            <div className="space-y-1 text-xs text-black bg-gray-200 shadow-md p-2">
              <div className="flex justify-between">
                {props.pricedesc && props.pricedesc !== "0"
                  ? props.pricedesc.toLowerCase()
                  : `$${props.price}`}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Price and Add Cart Button */}
      <div className="flex justify-center items-center p-4">
  <div className="flex flex-col sm:flex-row justify-between items-center bg-gray-50 rounded-xl p-2 shadow-md w-full sm:w-[80%] max-w-sm text-center">
    {!!props.price && (
      <span className="text-base md:text-lg font-bold bg-gray-200 px-2 py-1 rounded-lg mb-2 sm:mb-0">
        ${props.price}
      </span>
    )}
    <Button
      className="bg-primary text-white text-xs md:text-sm lg:text-base px-3 py-1 rounded-lg mt-2 sm:mt-0"
    >
      {props.btnText}
    </Button>
  </div>
</div>

    </div>
  );
};

export default Product;
