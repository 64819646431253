import { configureStore } from "@reduxjs/toolkit";
import mainSlice from "./slices/main.slice";
import adminSlice from "./slices/admin.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const main = persistReducer(persistConfig, mainSlice);
const admin = persistReducer(persistConfig, adminSlice);

export const store = configureStore({
  reducer: { main, admin },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
