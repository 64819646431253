import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DiscountIcon from "@mui/icons-material/Discount";
import ElectricBikeIcon from "@mui/icons-material/ElectricBike";
import FitbitIcon from "@mui/icons-material/Fitbit";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import StoreIcon from "@mui/icons-material/Store";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import MenuIcon from '@mui/icons-material/Menu';
import Button from "components/common/Button";
import api from "config/api";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
type Props = {};

const inventoryItems: any = {
  OnlineOrderingStatus: {
    type: "boolean",
    label: "Don't Allow online ordering",
    Icon: PeopleAltIcon,
    className: "lg:col-span-2",
    description:
      "Don't allow user to place order from the site, if OFF user can't access the site",
  },
  ClosePickupOrder: {
    type: "boolean",
    label: "Close pickup order",
    Icon: StoreIcon,
    description: "Close pick up type order from store",
  },
  CloseDeliveryOrder: {
    type: "boolean",
    label: "Close delivery order",
    Icon: DeliveryDiningIcon,
    description: "Close delivery type order from store",
  },
  PickupOnlinePayment: {
    type: "boolean",
    label: "Allow online payment in pickup",
    Icon: AddBusinessIcon,
    description: "Allow user to make online payment when order type is pick up",
  },
  DeliveryOnlineOrder: {
    type: "boolean",
    label: "Allow online payment in delivery",
    Icon: AddCardIcon,
    description:
      "Allow user to make online payment when order type is delivery",
  },
  DontPayAtStorePickup: {
    type: "boolean",
    label: "Don't allow pay at store in pickup",
    Icon: ShoppingCartIcon,
    description:
      "Don't allow user to pay at count / store when order type is pick up",
  },
  DontPayAtStoreDelivery: {
    type: "boolean",
    label: "Don't allow pay at store in delivery",
    Icon: ShoppingCartCheckoutIcon,
    description:
      "Don't allow user to pay at count / store when order type is deliver",
  },
  ShowCompanyName: {
    type: "boolean",
    label: "Show company name",
    Icon: FormatColorTextIcon,
    description: "Show company name on the user website",
  },
  ShowLogo: {
    type: "boolean",
    label: "Show App Logo",
    Icon: FitbitIcon,
    description: "Show app logo name on the user website",
  },
  ApplyTakeOutDeliveryPrice: {
    type: "boolean",
    label: "Apply takeout delivery price",
    Icon: DirectionsBikeIcon,
    description: "Should apply extra charges for delivery and takeout",
  },
  ApplyDeliveryChargesAsKm: {
    type: "boolean",
    label: "Apply delivery charges as KM",
    Icon: ElectricBikeIcon,
    description: "Apply delivery changes to user as KM",
  },

  MinimumDeliveryOrder: {
    type: "boolean",
    label: "Minimum delivery order",
    Icon: TextDecreaseIcon,
    description: "Check minimum amount on order with type delivery",
  },
  OnlineDeliveryBoundry: {
    type: "boolean",
    label: "Online delivery boundary",
    Icon: TextIncreaseIcon,
    description: "Check maximum amount on online delivery",
  },
  AllowDiscountOnDelivery: {
    type: "boolean",
    label: "Allow discount on delivery",
    Icon: LocalOfferIcon,
    description: "Allow discounts on order type delivery",
  },
  AllowDiscountOnPickup: {
    type: "boolean",
    label: "Allow discount on pickup",
    Icon: LocalOfferIcon,
    description: "Allow discounts on order type pickup",
  },
  OnlineSpecialDiscount: {
    type: "number",
    label: "Online special discount",
    Icon: DiscountIcon,
    description: "Allow special discounts on the website",
  },
  DontDisplayTipOption: {
    type: "boolean",
    label: "Don't display tip options",
    Icon: TipsAndUpdatesIcon,
    description: "Tips should not be shown in user panel",
  },
  DisplayLocationOnCheckout: {
    type: "boolean",
    label: "Display location on checkout",
    Icon: AddLocationIcon,
    description:
      "Display the delivery location while checkout in delivery orders",
  },
  menuDirection: {
    type: "direction", 
    label: "Menu Position",
    Icon: MenuIcon,
    description: "Set menu position to 'Top' or 'Left'",
    options: ["Top", "Left"],
  },
};

const OptionItem = ({
  Icon,
  label,
  description,
  isSelected,
  onToggle,
  type,
  className = "",
  options = [],
}: any) => (
  <div
    className={`flex max-sm:flex-col bg-primary/10 rounded-lg p-3 justify-between items-center ${className}`}
  >
    <div className="flex max-sm:flex-col items-center gap-3 max-md:max-w-[80%]">
      <Icon className="!text-5xl" />
      <div className="max-sm:text-center max-sm:mb-3">
        <b className="text-base">{label}</b>
        <p className="text-sm">{description}</p>
      </div>
    </div>
    {type === "number" ? (
      <input
        className="border bg-primary/20 outline-none ring-0 max-w-[60px] rounded-md px-2 py-1 text-end appearance-none"
        type="number"
        max="100"
        value={isSelected}
        onChange={(e) =>
          onToggle(+e.target.value <= 100 ? e.target.value : 100)
        }
      />
    ) : type === "direction" && options.length === 2 ? (
      // Custom toggle for direction type
      <div className="flex rounded-full overflow-hidden cursor-pointer select-none min-w-[100px] transition-all relative bg-primary/20">
        {options.map((option:string, index:number) => (
          <motion.div
            key={option}
            onClick={() => onToggle(option)}
            className={`w-1/2 text-center py-1 ${
              isSelected === option ? "bg-primary text-white" : "bg-primary/20"
            }`}
          >
            {option}
          </motion.div>
        ))}
      </div>
    ) : (
      <motion.div
        className={`flex rounded-full overflow-hidden cursor-pointer select-none p-1 min-w-[60px] transition-all relative ${
          isSelected ? "bg-primary" : "bg-primary/20"
        }`}
        onClick={() => onToggle(+!isSelected)}
      >
        <motion.p
          className="absolute top-1.5 left-2 text-xs text-white"
          initial={{ opacity: 0 }}
          animate={{ opacity: isSelected }}
        >
          ON
        </motion.p>
        <motion.p
          className="absolute top-1.5 right-2 text-xs text-black"
          initial={{ opacity: 0 }}
          animate={{ opacity: +!isSelected }}
        >
          OFF
        </motion.p>
        <motion.div
          className={`size-5 bg-white rounded-full`}
          initial={{ translateX: 0 }}
          animate={{ translateX: isSelected ? 32 : 0 }}
          transition={{ type: "tween" }}
        />
      </motion.div>
    )}
  </div>
);


const ProjectSetting = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  
  // console.log("🚀 - ProjectSetting - data:", data);

  const options = useMemo(() => Object.entries(inventoryItems), []);

  useEffect(() => {
    api
      .get("/api/Option/GetSettings")
      .then(({ data }) => {
        console.log("Apisetting data =>",data);
        
        setData(
          Object.entries(data).reduce(
            (e: any, [Name, Value]: any) => ({
              ...e,
              [Name]: { Id: 0, Value: isNaN(+Value) ? Value : +Value, Name },
            }),
            {}
          )
        );
      })
      .catch((e) => {
        setData(
          Object.keys(inventoryItems).reduce(
            (e: any, Name) => ({ ...e, [Name]: { Id: 0, Value: 0, Name } }),
            {}
          )
        );
      });      
  }, []);

  const updateValue = (key: keyof typeof inventoryItems, value: string) => {
    
    setData((e: any) => {
      const obj = structuredClone(e);
      obj[key].Value = value;
      return obj;
    });
  };

  const handelSubmit = () => {
    if (data.ClosePickupOrder.Value && data.CloseDeliveryOrder.Value) {
      toast.error("Pickup and delivery both can't be closed");
      return;
    }

    if (!data.PickupOnlinePayment.Value && data.DontPayAtStorePickup.Value) {
      toast.error(
        "pay at store and online payment both can't be disabled for pickup"
      );
      return;
    }
    if (!data.DeliveryOnlineOrder.Value && data.DontPayAtStoreDelivery.Value) {
      toast.error(
        "pay at store and online payment both can't be disabled for delivery"
      );
      return;
    }
    
    setLoading(true);
    api
      .post("/api/Option/AddUpdateSettings", Object.values(data))
      .then((res) => {
        console.log("res",res);
        
        toast.success("Settings updated successfully");
      })
      .catch((e) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="w-full flex justify-center">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full overflow-y-auto p-4 max-h-full">
        {options.map(([title, item]: any) => (
          <OptionItem
            {...item}
            {...(inventoryItems?.[title] || {})}
            isSelected={data?.[title]?.Value}
            onToggle={(v: string) => updateValue(title, v)}
          />
        ))}
        <div className="w-full flex justify-center mt-2 col-span-1 lg:col-span-2">
          <Button disabled={loading} onClick={handelSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectSetting;
