import React, { useState } from "react";
import logo from "assets/images/login-image.png";
import Input from "components/common/Input";
import Button from "components/common/Button";
import api from "config/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { setUserData } from "store/slices/main.slice";
type Props = {};

const Login = (props: Props) => {
  const cart = useAppSelector(({ main }) => main.cart);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    emailId: "",
    password: "",
  });

  const handleSubmit = () => {
    toast.dismiss();
    if (!data.emailId) {
      toast.error("Email is required");
      return;
    }
    if (!data.password) {
      toast.error("Password is required");
      return;
    }
    setLoading(true);
    api(`/api/Customer/ByIdPass?${new URLSearchParams(data)}`)
      .then(async ({ data }) => {
        for (let item of cart) {
          await api.post("/api/Cart/AddCart", [
            { ...item, id: 0, userId: data.clientId },
          ]);
        }
        dispatch(setUserData(data));
        toast.success("Login Successfully");
        navigate("/");
      })
      .catch((e) => {
        toast.error("Invalid Email or Password");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="bg-background h-screen flex justify-center items-center">
      <div className="bg-white rounded-xl overflow-hidden flex gap-2 py-8 lg:py-2 px-6">
        <div className="flex max-md:hidden">
          <img src={logo} className="h-[200px] lg:h-[350px]" />
        </div>
        <div className="flex flex-col gap-2 justify-center items-center px-8">
          <h6 className="font-bold text-primary text-xl">
            LOGIN TO RANGER POS
          </h6>
          <Input
            size="small"
            label="Email"
            variant="standard"
            value={data.emailId}
            sx={{ minWidth: [200, 270] }}
            onValueChange={(emailId) => setData((e) => ({ ...e, emailId }))}
          />
          <Input
            size="small"
            label="Password"
            type="password"
            variant="standard"
            value={data.password}
            sx={{ minWidth: [200, 270] }}
            onValueChange={(password) => setData((e) => ({ ...e, password }))}
          />
          <div className="self-end">
            <p className="text-[12px] text-primary font-semibold">
              Forget password?
            </p>
          </div>
          <Button
            disabled={loading}
            className="mt-6 w-full"
            onClick={handleSubmit}
          >
            LOGIN
          </Button>
          <p className="text-[13px]">
            Don't have an account?{" "}
            <a href="/register" className="font-bold text-primary">
              Register
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
