import React from "react";
import closed from "assets/images/we-are-closed.svg";

type Props = {};

const ShopClosed = (props: Props) => {
  return (
    <div className="flex min-h-screen justify-center items-center">
      <img src={closed} />
    </div>
  );
};

export default ShopClosed;
