import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { TextareaAutosize } from "@mui/material";
import Button from "components/common/Button";
import GoogleAddress from "components/common/GoogleAddress";
import Input from "components/common/Input";
import TextArea from "components/common/TextAreawithLabel";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useEffect, useMemo, useState } from "react";
import { placeOrder, updateCheckoutData } from "store/slices/main.slice";
import { ORDER } from "utils/constants";

type Props = {
  setStage: (v: string) => void;
};

const CheckoutView = ({ setStage }: Props) => {
  const [tip, setTip] = useState(0);
  const [notes, setnotes] = useState("");
  const dispatch = useAppDispatch();
  const checkoutData = useAppSelector(({ main }) => main.checkoutData);
  const { settings, companyData } = useAppSelector(({ main }) => main);

  useEffect(() => {
    if (checkoutData?.tip && !tip) {
      setTip(checkoutData.tip);
    }
  }, [checkoutData, tip]);

  const updateTip = (v: number) => {
    setTip(v);
    dispatch(updateCheckoutData({ tip: v }));
  };

  const orderType = useMemo(
    () => checkoutData?.orderType,
    [checkoutData?.orderType]
  );

  const allowPayAtCounter = useMemo(() => {
    if (orderType === ORDER.TAKE_OUT) {
      return !settings.DontPayAtStorePickup;
    }
    if (orderType === ORDER.DELIVERY) {
      return !settings.DontPayAtStoreDelivery;
    }

    return true;
  }, [settings.DontPayAtStorePickup, orderType]);

  const allowOnlinePayment = useMemo(() => {
    if (orderType === ORDER.TAKE_OUT) {
      return !!settings.PickupOnlinePayment;
    }
    if (orderType === ORDER.DELIVERY) {
      return !!settings.DeliveryOnlineOrder;
    }

    return true;
  }, [settings.DontPayAtStorePickup, orderType]);

  const specialDiscount = useMemo(() => {
    if (
      !(
        (settings.AllowDiscountOnDelivery && orderType === ORDER.DELIVERY) ||
        (settings.AllowDiscountOnPickup && orderType === ORDER.TAKE_OUT)
      )
    )
      return 0;

    return +(
      (checkoutData.total * +settings.OnlineSpecialDiscount) /
      100
    ).toFixed(2);
  }, [settings, orderType]);

  const deliveryCharge = useMemo(() => {
    if (orderType !== ORDER.DELIVERY) return 0;

    return companyData?.deliveryCharge || 0;
  }, [orderType]);

  if (!checkoutData) return <></>;
  return (
    <>
      <div className="flex justify-between items-center px-4 pb-2 border-b">
        <div onClick={() => setStage("DETAILS")}>
          <ArrowBackIosNewIcon className="!text-[15px] !text-primary cursor-pointer" />
        </div>
        <h4 className="!text-lg text-primary font-semibold text-center">
          Check Out
        </h4>
        <div />
      </div>
      <div className="px-4 max-h-[75vh] overflow-auto">
        <div className="pt-2.5">
          {checkoutData?.finalBill?.map((i: any) => (
            <div className="flex px-2 justify-between border-b border-dashed pt-0.5">
              <p className="font-bold text-[14px]">{i.title}</p>
              <p className="font-bold text-[14px]">${i.amt.toFixed(2)}</p>
            </div>
          ))}
          {!settings.DontDisplayTipOption && (
            <div className="flex px-2 justify-between border-b border-dashed pt-0.5">
              <p className="font-bold text-[14px]">Tips</p>
              <p className="font-bold text-[14px]">${tip.toFixed(2)}</p>
            </div>
          )}
          {!!specialDiscount && (
            <div className="flex px-2 justify-between border-b border-dashed pt-0.5">
              <p className="font-bold text-[14px]">Special Discount</p>
              <p className="font-bold text-[14px]">${specialDiscount}</p>
            </div>
          )}
          {orderType === ORDER.DELIVERY && !!deliveryCharge && (
            <div className="flex px-2 justify-between border-b border-dashed pt-0.5">
              <p className="font-bold text-[14px]">Delivery Charge</p>
              <p className="font-bold text-[14px]">
                ${deliveryCharge.toFixed(2)}
              </p>
            </div>
          )}
          <div className="flex justify-between mt-2 rounded-lg py-2 px-2 bg-primary/20">
            <p className="font-bold text-[14px]">Total</p>
            <p className="font-bold text-[14px]">
              ${(checkoutData.total + tip + deliveryCharge).toFixed(2)}
            </p>
          </div>
        </div>
        {!!settings.DisplayLocationOnCheckout && (
          <div className="rounded-lg py-2 px-2 bg-primary/20 mt-2">
            <p className="text-sm text-center">
              Your order will be placed on below location
            </p>
            <p className="text-base text-center text-primary">
              {companyData?.address1} {companyData?.address2}
            </p>
          </div>
        )}
        {!settings.DontDisplayTipOption && (
          <div className="flex items-center gap-1.5 mt-2">
            <p className="font-bold min-w-[15%]">Tips :</p>
            <Button
              onClick={() =>
                updateTip(+((checkoutData.total * 10) / 100).toFixed(2))
              }
            >
              10%
            </Button>
            <Button
              onClick={() =>
                updateTip(+((checkoutData.total * 15) / 100).toFixed(2))
              }
            >
              15%
            </Button>
            <Button
              onClick={() =>
                updateTip(+((checkoutData.total * 20) / 100).toFixed(2))
              }
            >
              20%
            </Button>
            <Input
              size="small"
              sx={{ flex: 1 }}
              value={tip}
              onValueChange={(t) => +t >= 0 && updateTip(+t)}
            />
          </div>
        )}
        <div  className="flex w-full justify-center ">
         <div className="w-[50%] mt-5">
         <TextArea
      label="Order Notes"
      value={notes}
      onChange={(e) => setnotes(e.target.value)}
      placeholder="Add any special instructions..."
    />
    </div>
    </div>
      </div>
      <div className="border-b w-full mt-2 mb-3 flex-1" />
     
      {allowPayAtCounter && (
        <Button
          className="mx-4"
          onClick={() => {
            dispatch(
              placeOrder({
                tip,
                deliveryCharge,
                extra: deliveryCharge,
                paymentType: "oncounter",
                setStage,
                notes:notes,
              })
            );
          }}
        >
          Pay at Counter
        </Button>
      )}
      {allowOnlinePayment && (
        <Button
          className="mx-4 mt-2"
          onClick={() => {
            dispatch(
              updateCheckoutData({
                tip,
                deliveryCharge,
                total: checkoutData.total + tip + deliveryCharge,
              })
            );
            setStage("PAYMENT");
          }}
        >
          Pay Now
        </Button>
      )}
    </>
  );
};

export default CheckoutView;
