import AdminContainer from "./admin";
import UserContainer from "./user";

const Container = () => {
  if (window.location.pathname.includes("admin")) {
    return <AdminContainer />;
  }
  return <UserContainer />;
};

export default Container;
