import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Button from "components/common/Button";
import LoginButton from "components/user/LoginButton";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { toggleCart, toggleDrawer } from "store/slices/main.slice";

const ToolBar = () => {
  const dispatch = useAppDispatch();
  const cartLength = useAppSelector(({ main }) => main.cart.length);
  const { settings, companyData } = useAppSelector(({ main }) => main);

  return (
    <div className="h-[60px] bg-primary px-[20px] flex items-center justify-between shadow-[0px_10px_10px_5px_#00000024] z-10 ">
      <div className="flex items-center">
        <div className="md:hidden" onClick={() => dispatch(toggleDrawer())}>
          <MenuIcon className="text-light-text cursor-pointer -ml-2" />
        </div>
        <a href="/admin/setting/time" className="text-white !px-1.5 relative">
          <AdminPanelSettingsIcon />
        </a>

        <a href="/">
          <h1 className="text-xl font-bold text-light-text">
            {settings.ShowCompanyName && companyData?.companyName
              ? companyData?.companyName
              : "RANGER POS"}
          </h1>
        </a>
      </div>
      <div className="flex gap-2">
        <LoginButton />
        <Button
          className="bg-white !text-dark-text !px-2 relative"
          onClick={() => dispatch(toggleCart())}
        >
          <ShoppingCartIcon className="!text-[18px]" />
          {!!cartLength && (
            <div className="bg-secondary text-light-text text-[10px] absolute h-5 w-5 rounded-full flex justify-center items-center -top-[8px] -right-[8px]">
              {cartLength}
            </div>
          )}
        </Button>
      </div>
    </div>
  );
};

export default ToolBar;
