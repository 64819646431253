import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import api from "config/api";
import { format } from "date-fns";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { AppDispatch } from "store";
import {
  CompanyData,
  ProductType,
  SettingType,
  SideBarItem,
  TaxesType,
  TimeItem,
} from "utils/types";

type InititalStateType = {
  loading: boolean;
  cart: any[];
  checkoutData: any;
  pizzaList: any[];
  userData: any;
  taxes: TaxesType[];
  settings: SettingType;
  showCart: boolean;
  showUserDetailModel: boolean;
  showDrawer: boolean;
  companyData?: CompanyData;
  sideBarMenu: SideBarItem[];
  selectedProduct: ProductType | undefined;
  validTimes: TimeItem[];
  gestData:any;
};

/*
OnlineOrderingStatus ✅
ClosePickupOrder✅
CloseDeliveryOrder✅
PickupOnlinePayment✅
DeliveryOnlineOrder✅
DontPayAtStorePickup✅
DontPayAtStoreDelivery✅
ShowCompanyName✅
ShowLogo 🔴
ApplyTakeOutDeliveryPrice🔴
ApplyDeliveryChargesAsKm 🔴
DontDisplayTipOption ✅
MinimumDeliveryOrder 🔴
OnlineDeliveryBoundry 🔴
AllowDiscountOnDelivery ✅
AllowDiscountOnPickup✅
OnlineSpecialDiscount ✅
DisplayLocationOnCheckout✅

extra flags 
AllowFutureDate
*/
const initialSetting = {
  OnlineCombineLineQty: 0,
  AllowFutureDate: 1,
  MapAPIKeySetting: "",
  EnableGiftcardPromo: 0,
  DisplayLocationOnCheckout: 0,
  AllowDiscountOnDelivery: 1,
  AllowDiscountOnPickup: 1,
  DontDisplayTipOption: 0,
  ApplyTakeOutDeliveryPrice: 0,
  DontDisplayPizzaSubLevel: 0,
  DisplayDefaultCrustOnline: 0,
  OnlineSpecialDiscount: 1,
  OnlineDeliveryBoundry: 0,
  ApplySpecialDiscountOnward: 0,
  MinimumDeliveryOrder: 0,
  DeliveryOrderOptions: 1,
  ShowLogo: 0,
  ShowCompanyName: 1,
  PickupOrderOptions: 1,
  DontPayAtStorePickup: 1,
  DontPayAtStoreDelivery: 1,
  PaypalPickup: 0,
  PaypalDelivery: 0,
  AuthorizePickup: 0,
  AuthorizeDelivery: 0,
  MonerisPickup: 0,
  MonerisDelivery: 0,
  StripePickup: 0,
  StripeDelivery: 0,
  ElavonPickup: 0,
  ElavonDelivery: 0,
  TDPickup: 0,
  TDDelivery: 0,
  GlobalPickup: 0,
  GlobalDelivery: 0,
  CloverPickup: 0,
  CloverDelivery: 0,
  PickupOnlinePayment: 1,
  DeliveryOnlineOrder: 1,
  ApplyDeliveryChargesAsKm: 1,
  ApplySpecialDiscountAsTotal: 0,
  DisplayDefaultToppings: 0,
  OnlineOrderingStatus: 1,
  ClosePickupOrder: 1,
  CloseDeliveryOrder: 0,
  menuDirection: "",
  MinimumDeliveryOrderAmt: "",
  DefaultCrustName: "",
};

const initialState: InititalStateType = {
  loading: true,
  cart: [],
  pizzaList: [],
  userData: undefined,
  showCart: false,
  showUserDetailModel: false,
  showDrawer: false,
  taxes: [],
  settings: initialSetting,
  sideBarMenu: [],
  selectedProduct: undefined,
  checkoutData: undefined,
  validTimes: [],
  companyData: undefined,
  gestData: undefined,

};

export const mainSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setInitialData: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.sideBarMenu = action.payload.categories;
      state.taxes = action.payload.taxes;
      state.companyData = action.payload.companyData;
      state.pizzaList = [];
      state.settings = {
        ...initialSetting,
        ...(Object.entries(action.payload.settings).reduce(
          (obj, [k, v]: any) => ({ ...obj, [k]: isNaN(+v) ? v : +v }),
          {}
        ) as any),
      };
    },
    setCheckoutData: (state, action: PayloadAction<any>) => {
      state.checkoutData = action.payload;
    },
    updateCheckoutData: (state, action: PayloadAction<any>) => {
      state.checkoutData = { ...state.checkoutData, ...action.payload };
    },
    setSelected: (state, action: PayloadAction<any>) => {
      state.selectedProduct = action.payload;
    },
    setPizzaList: (state, action: PayloadAction<any>) => {
      state.pizzaList = action.payload;
    },
    setUserData: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    setAllCartData: (state, action: PayloadAction<any>) => {
      state.cart = action.payload || [];
    },
    setGestData: (state, action: PayloadAction<any>) => {
      state.gestData = action.payload;
    },
    addToCart: (state, action: PayloadAction<any>) => {
      const userId = state?.userData?.clientId;
      const item = action.payload;
      if (item?.data.isEdit) {
        if (userId) {
          api
            .post("/api/Cart/UpdateCart", [
              { ...item, cartId: state.cart[item.data.index].cartId, userId },
            ])
            .then(({ data }) => {
              console.log("Added to cart", data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        state.cart[item.data.index] = item;
      } else if (item) {
        state.cart.push(item);
        if (userId) {
          api
            .post("/api/Cart/AddCart", [{ ...item, cartId: 0, userId }])
            .then(({ data }) => {
              console.log("Added to cart", data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    setValidTimes: (state, action: PayloadAction<any>) => {
      state.validTimes = action.payload;
    },
    removeFromCart: (state, action: PayloadAction<number>) => {
      const userId = state?.userData?.clientId;
      let cartId = -1;
      state.cart = state.cart.filter((item, i) => {
        if (i !== action.payload) return true;
        cartId = item.cartId;
        return false;
      });

      if (userId && cartId !== -1) {
        api
          .post("/api/Cart/DeleteCart?id=" + cartId)
          .then(({ data }) => {
            console.log("deleted from cart", data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    removeAllFromCart: (state) => {
      state.cart = [];
      const userId = state?.userData?.clientId;
      if (userId) {
        api
          .post("/api/Cart/DeleteUserCart?userid=" + userId)
          .then(({ data }) => {
            console.log("Delete entire cart", data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    toggleCart: (state) => {
      state.showCart = !state.showCart;
    },
    toggleDrawer: (state) => {
      state.showDrawer = !state.showDrawer;
    },
    toggleUserDetailModel: (state) => {
      state.showUserDetailModel = !state.showUserDetailModel;
    },
  },
});

export const fetchInitialData =
  (navigate: NavigateFunction) =>
  async (dispatch: AppDispatch, getState: any) => {
    const clientId = getState()?.main?.userData?.clientId;

    dispatch(toggleLoading());
    api.get("/api/initialData").then(({ data }) => {
      document.title = data.companyData.companyName;
      console.log("data",data);
      
      //! update logo dynamically
      // let link: any = document.querySelector("link[rel~='icon']");
      // if (!link) {
      //   link = document.createElement("link");
      //   link.rel = "icon";
      //   document.getElementsByTagName("head")[0].appendChild(link);
      // }
      // link.href = "https://stackoverflow.com/favicon.ico";
      const search = new URLSearchParams(window.location.search);
      if (!search.get("product")) {
        search.set("product", data.categories[0].id);
        navigate(
          {
            pathname: window.location.pathname,
            search: search.toString(),
          },
          { replace: true }
        );
      }
      dispatch(setInitialData(data));
    });
    if (clientId) {
      api.get("/api/Cart/GetUserCart?userid=" + clientId).then((res) => {
        dispatch(setAllCartData(res.data));
      });
    }
    api.get("/api/RestTime/GetResttimes").then(({ data }) => {
      dispatch(setValidTimes(data));
    });
  };

export const placeOrder =
  ({ tip, paymentType, setStage, extra = 0,notes="" }: any) =>
  (dispatch: any, getState: any) => {
    const { checkoutData, cart } = getState().main;

    const taxes = cart.reduce(
      (obj: any, itm: any) => {
        if (itm.taxId1) {
          obj.taxId1 = 1;
          obj.totalTax1 = +(obj.totalTax1 + itm.totalTax1).toFixed(5);
        }
        if (itm.taxId2) {
          obj.taxId2 = 2;
          obj.totalTax2 = +(obj.totalTax2 + itm.totalTax2).toFixed(5);
        }
        if (itm.taxId3) {
          obj.taxId3 = 3;
          obj.totalTax3 = +(obj.totalTax3 + itm.totalTax3).toFixed(5);
        }

        return obj;
      },
      {
        taxId1: 0,
        taxId2: 0,
        taxId3: 0,
        totalTax1: 0,
        totalTax2: 0,
        totalTax3: 0,
      }
    );

    const subTotal =
      checkoutData.finalBill.find((i: any) => i.title === "SubTotal")?.amt ?? 0;
    const params = {
      clientId: checkoutData.clientId,
      clientName: checkoutData.firstName,
      paymentType,
      ...taxes,
      stationId: 0, // static
      subTotal,
      notes:notes,
      orderTotal: +(checkoutData.total + extra).toFixed(2),
      promoCode: "", // static
      serviceCharge: "", // static
      promoDiscount: 0, // static
      serviceFee: 0, // static
      bagFee: 0, // static
      tip: tip ?? checkoutData?.tip ?? 0,
      id: 0, // static
      suitNo: "", // blank
      buzzerNo: "", // blank
      roomNo: "", // blank
      firstName: checkoutData.firstName,
      lastName: checkoutData.lastName,
      email: checkoutData.email,
      address: checkoutData.address,
      date: null, //null
      time: null, //null
      orderType: checkoutData.orderType,
      onDate: format(new Date(checkoutData.onDate), "MM/dd/yyyy"),
      onTime: checkoutData.onTime + ":00",
      orderDetails: cart,
    };

    api
      .post("/api/OrderSubmition", params)
      .then(({ data }) => {
        setStage("CART");
        dispatch(setCheckoutData(undefined));
        dispatch(setSelected(undefined));
        dispatch(setGestData(undefined))
        dispatch(toggleCart());
        dispatch(removeAllFromCart());
        toast.success("Order Places Successfully!");
      })
      .catch((e) => {
        toast.error("Something went wrong");
      });
  };
export const {
  toggleLoading,
  removeAllFromCart,
  toggleDrawer,
  setPizzaList,
  setInitialData,
  setUserData,
  setSelected,
  addToCart,
  removeFromCart,
  toggleCart,
  setAllCartData,
  setCheckoutData,
  toggleUserDetailModel,
  updateCheckoutData,
  setValidTimes,
  setGestData
} = mainSlice.actions;

export default mainSlice.reducer;
