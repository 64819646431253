import { useAppSelector } from "hooks/store";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginAdmin from "screens/auth/LoginAdmin";
import ToolBar from "components/admin/ToolBar";
import Sidebar from "components/admin/Sidebar";
import AllOrders from "./AllOrders";
import ProjectSetting from "./ProjectSetting";
import TimeSetting from "./TimeSetting";
import InventorySetting from "./InventorySetting";
import AddImage from "./AddImage";

type Props = {};

const AdminContainer = (props: Props) => {
  const adminData = useAppSelector(({ admin }) => admin.adminData);

  if (!adminData) return <LoginAdmin />;

  return (
    <div className="min-h-screen">
      <ToolBar />
      <div className="flex h-[calc(100vh-60px)] relative">
        <Sidebar />
        <Routes>
          <Route
            path="/admin"
            element={<Navigate to="/admin/setting/time" replace />}
          />
          <Route path="/admin/orders" element={<AllOrders />} />
          <Route path="/admin/setting/project" element={<ProjectSetting />} />
          <Route path="/admin/setting/time" element={<TimeSetting />} />
          <Route path="/admin/inventory" element={<InventorySetting />} />
          {/* <Route path="/admin/add-image" element={<AddImage />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default AdminContainer;
