import EditProductDialog from "components/admin/EditProductDialog";
import Loading from "components/common/Loading";
import HorizontalMenu from "components/user/HorizontalBar";
import Product from "components/user/Product";
import api from "config/api";
import React, { useEffect, useState } from "react";
import Listing from "screens/user/Listing";
import { fetchInitialData } from "store/slices/main.slice";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/store";

type Props = {};

const InventorySetting = (props: Props) => {
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectProduct, setSelectProduct] = useState<any>(undefined);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("product")) {
      dispatch(fetchInitialData(navigate));
    }
  }, [searchParams, dispatch, navigate]);
  // const fetchProducts = () => {
  //   setLoading(true);
  //   setProducts([]);
  //   api
  //     .get("/api/Product")
  //     .then(({ data }) => {
  //       setProducts(data);
  //     })
  //     .finally(() => setLoading(false));
  // };
  // useEffect(() => {
  //   fetchProducts();
  // }, []);

  return (
    <div className="h-full w-full flex flex-col relative z-0 overflow-hidden">
      <HorizontalMenu />
      <div className="flex-1 overflow-y-auto h-full">
        <Listing />
      </div>
      {/* <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 overflow-y-auto items-start max-h-full p-4 ">
        {products.map((item) => (
          <Product
            {...item}
            btnText="Edit"
            onClick={() => setSelectProduct(item)}
          />
        ))}
      </div> */}
      {/* {loading && <Loading />} */}
      {/* <EditProductDialog
        selected={selectProduct}
        setSelected={setSelectProduct}
        onComplete={() => {
          setSelectProduct(undefined);
          fetchProducts();
        }}
      /> */}
    </div>
  );
};

export default InventorySetting;
