import { Dialog } from "@mui/material";
import Button from "components/common/Button";
import { useEffect, useMemo, useState } from "react";
import { ProductType } from "utils/types";
import noImage from "assets/images/no-image.png";
import { Edit, Upload } from "@mui/icons-material";
import api, { baseURL } from "config/api";
import { toast } from "react-toastify";
type Props = {
  selected?: ProductType;
  setSelected: (v?: any) => void;
  onComplete: () => void;
};

const EditProductDialog = ({ setSelected, selected, onComplete }: Props) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | undefined>(undefined);
  const [data, setData] = useState<ProductType | undefined>(undefined);

  useEffect(() => {
    if (selected) {
      setData(selected);
    }
  }, [selected]);

  const handleChange = (e: any) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = () => {
    if (!data) return;
    if (!image) {
      toast.error("Image is required");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("formFile", image);
    api
      .post("/api/FileUtility/UploadFile?itemid=" + data.productId, formData)
      .then((res) => {
        console.log("res",res);
        
        toast.success("Product updated successfully");
        onComplete();
      })
      .catch((e) => {
        console.log("🚀 - api.post - e:", e);
      })
      .finally(() => setLoading(false));
  };

  const imageUrl = useMemo(() => {
    if (!data) return noImage;
    if (image) {
      return URL.createObjectURL(image);
    }
    return data.imageUrl;
  }, [image, data]);

  if (!data) return <></>;

  return (
    <Dialog onClose={() => setSelected()} open={!!selected}>
      <div className="min-w-[80vw] md:min-w-[600px] min-h-[70vh] py-2.5 flex flex-col">
        <h4 className="!text-lg text-primary font-semibold text-center border-b pb-2">
          Update Product
        </h4>
        <div className="border-b px-4 py-0.5 text-[15px] flex-1 flex flex-col gap-3 pt-3">
          <div className="size-[150px] aspect-square self-center border rounded-full relative">
            <img
              src={imageUrl}
              className="h-full rounded-full"
              onError={(e: any) => {
                e.target.src = noImage;
              }}
            />
            <input
              id="image-input"
              type="file"
              onChange={handleChange}
              className="hidden"
            />
            <div
              className="size-7 flex justify-center items-center absolute bottom-[5%] right-[5%] rounded-full bg-primary cursor-pointer"
              onClick={() => {
                const input = document.getElementById("image-input")!;
                input.click();
              }}
            >
              <Edit className="!text-[15px] text-white" />
            </div>
          </div>

          <h1 className="font-bold">{data.productName}</h1>
        </div>
        <Button className="mt-3 mx-3" disabled={loading} onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Dialog>
  );
};

export default EditProductDialog;
