import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { toggleDrawer } from "store/slices/main.slice";
import { title } from "process";
import { setAdminData } from "store/slices/admin.slice";

const navItems = [
  { url: "/admin/setting/time", title: "Time Setting" },
  { url: "/admin/setting/project", title: "Project Setting" },
  { url: "/admin/inventory", title: "Inventory" },
  { url: "/admin/orders", title: "All Orders" },
  { url: "", title: "Log Out" },
];
type Props = {};

const Sidebar = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery("(min-width:768px)");
  const showDrawer = useAppSelector(({ admin }) => admin.showDrawer);

  return (
    <>
      {!isTablet && showDrawer && (
        <div
          className="absolute top-0 bottom-0 left-0 right-0 bg-black/30 z-10"
          onClick={() => dispatch(toggleDrawer())}
        />
      )}
      <motion.div
        className="min-w-[180px] h-full border-r border-primary bg-white px-2 pt-3 max-md:absolute z-20 max-md:shadow-2xl overflow-hidden"
        initial={{ translateX: !isTablet ? -180 : 0 }}
        animate={{ translateX: showDrawer || isTablet ? 0 : -180 }}
        transition={{ duration: 0.4 }}
      >
        {navItems.map(({ title, url }) => (
          <div
            className={`capitalize transition-all duration-300 py-1.5 px-3 mt-1 rounded-lg cursor-pointer font-medium ${
              window.location.pathname === url
                ? "bg-primary text-light-text"
                : "hover:bg-primary/20"
            }`}
            onClick={() => {
              if (url) {
                navigate(url);
              } else {
                dispatch(setAdminData(undefined));
              }
            }}
          >
            {title}
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default Sidebar;
