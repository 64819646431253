import { Dialog } from "@mui/material";
import Button from "components/common/Button";
import GoogleAddress from "components/common/GoogleAddress";
import Input from "components/common/Input";
import api from "config/api";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { setUserData, toggleUserDetailModel } from "store/slices/main.slice";

type Props = {};
const initalData = {
  clientId: 0,
  firstName: "",
  lastName: "",
  phoneNo: "",
  email: "",
  password: "",
  address: "",
  address1: "",
};
const UserDetailPopUp = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [data, setData] = useState(initalData);
  const userData = useAppSelector(({ main }) => main.userData);
  const selectedProduct = useAppSelector(
    ({ main }) => main.showUserDetailModel
  );

  useEffect(() => {
    if (userData) setData(userData);
  }, [userData?.clientId,userData]);

  const closeModel = () => {
    dispatch(toggleUserDetailModel());
    // setData({ ...initalData });
  };

  const handleSubmit = () => {
    toast.dismiss();
    setLoading(true);

    if (!data.firstName || !data.lastName || !data.email) {
      toast.error("Something is missing");
      return;
    }
    if (data.phoneNo.length !== 10) {
      toast.error("Invalid mobile number");
      return;
    }
    api
      .post("/api/Customer/UpdateCustomerData", data)
      .then(({ data }) => {
        toast.success("User details updated successfully");
        dispatch(setUserData(structuredClone(data)));
        closeModel();
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };
  return (
    <Dialog onClose={closeModel} open={selectedProduct} maxWidth="lg">
      <div className="md:min-w-[600px] p-6 flex flex-col gap-3">
        <h4 className="!text-lg text-primary font-semibold text-center pb-1 border-b">
          User Details
        </h4>
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 mt-2">
          <Input
            size="small"
            type="text"
            label="First Name"
            value={data.firstName}
            onValueChange={(firstName) => setData((e) => ({ ...e, firstName }))}
          />
          <Input
            size="small"
            type="text"
            label="Last Name"
            value={data.lastName}
            onValueChange={(lastName) => setData((e) => ({ ...e, lastName }))}
          />
          <Input
            size="small"
            type="text"
            label="Phone No."
            value={data.phoneNo}
            onValueChange={(phoneNo) =>
              setData((e) => ({
                ...e,
                phoneNo: isNaN(+phoneNo) ? e.phoneNo : phoneNo,
              }))
            }
          />
          <Input
            size="small"
            type="text"
            label="Email"
            value={data.email}
            onValueChange={(email) => setData((e) => ({ ...e, email }))}
          />
          <GoogleAddress
            className="col-span-2"
            lable="Home Address"
            value={data.address}
            onChange={(address) => setData((e) => ({ ...e, address }))}
          />
          <GoogleAddress
            className="col-span-2"
            lable="Office Address"
            value={data.address1}
            onChange={(address1) => setData((e) => ({ ...e, address1 }))}
          />
        </div>
        <Button disabled={loading} onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Dialog>
  );
};

export default UserDetailPopUp;
