import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "components/common/Button";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useEffect, useMemo, useState } from "react";
import {
  removeAllFromCart,
  removeFromCart,
  setCheckoutData,
  setSelected,
} from "store/slices/main.slice";
import { ORDER } from "utils/constants";

type Props = {
  setStage: (v: string) => void;
};

const Item = ({ item, index, onAction, showDefaultToppings = 1 }: any) => {
  const toppings = useMemo(
    () =>
      item?.modifiers?.filter(
        (i: any) =>
          showDefaultToppings || !i.defaultTopping || i.newDefaultTopping
      ),
    [showDefaultToppings, item?.modifiers]
  );
  return (
    <div className="mx-4 px-1.5 [&:not(:last-child)]:border-b text-[12px] py-1.5 flex max-sm:flex-col sm:gap-2">
      <div className="flex-1">
        <p className="font-bold">{item.fullDescription}</p>
        {!!item.isDeal && (
          <p>
            <b>Pizza :</b> {/* {item.pizza.productName} */}
          </p>
        )}
        {!!item.isPizza && (
          <div className="flex gap-3">
            <p>
              <b>Size :</b> {item.pizzaSize.size} [${item.pizzaSize.price}]
            </p>
            {!!item.isDeal ? (
              <p>
                <b>Spicy :</b> {item.spiceLevel.spicename}
              </p>
            ) : (
              <p>
                <b>Crust :</b> {item.pizzaCrust.crustName}
              </p>
            )}
          </div>
        )}
        {!item.isPizza && item.selectedItemsLevel?.length > 0 && (
          <div>
            <p className="max-w-[450px]">
              <b> Modifications : </b>
              {item.selectedItemsLevel
                .map((i: any) => i.name + " $" + i.price)
                .join(", ")}
            </p>
          </div>
        )}
        {!!toppings?.length && (
          <p className="max-w-[450px]">
            <b>Toppings : </b>
            {toppings
              .map((i: any) => {
                let v = i.description;
                if (/* !i.isFree && */ !i.newDefaultTopping) {
                  v += ` [$${i.subTotal}]`;
                }
                return v;
              })
              .join(", ")}
          </p>
        )}
        {item.note && (
          <p>
            <b>Note :</b> {item.note}
          </p>
        )}
      </div>
      <div>
        <p className="font-bold">Total : ${item.subTotal.toFixed(2)}</p>
        <p>
          <b>Qty :</b> {item.quantity}
        </p>
        <div className="flex justify-end gap-1 mt-0.5">
          <div
            className="bg-primary rounded-md h-[22px] w-[22px] flex justify-center items-center cursor-pointer"
            onClick={() => onAction("edit", { ...item, index, isEdit: true })}
          >
            <EditIcon className="!text-[15px] !text-light-text" />
          </div>
          <div
            className="bg-primary rounded-md h-[22px] w-[22px] flex justify-center items-center cursor-pointer"
            onClick={() => onAction("delete", { index, item })}
          >
            <DeleteIcon className="!text-[15px] !text-light-text" />
          </div>
        </div>
      </div>
    </div>
  );
};
const CartView = ({ setStage }: Props) => {
  const dispatch = useAppDispatch();
  const { cart, taxes, settings, checkoutData, userData, companyData } =
    useAppSelector(({ main }) => main);
  const [orderType, setOrderType] = useState("");
  const [deleteDialog, setDeleteDialog] = useState<any>(undefined);

  useEffect(() => {
    if (settings.ClosePickupOrder) {
      setOrderType(ORDER.DELIVERY);
      return;
    }
    if (settings.CloseDeliveryOrder) {
      setOrderType(ORDER.TAKE_OUT);
      return;
    }
    setOrderType(checkoutData?.orderType || ORDER.TAKE_OUT);
  }, [checkoutData]);

  const placeOrder = () => {
    setStage("DETAILS");
    dispatch(setCheckoutData({ orderType, finalBill, total, cart }));
  };

  const handleCartAction = (type: string, data: any) => {
    if (type === "edit") {
      dispatch(setSelected(data));
    }
    if (type === "delete") {
      setDeleteDialog(data);
    }
  };

  const deliveryCharge = useMemo(() => {
    if (orderType !== ORDER.DELIVERY) return 0;

    return companyData?.deliveryCharge || 0;
  }, [orderType]);

  const { finalBill, total } = useMemo(() => {
    const list: any[] = [
      { title: "SubTotal", amt: 0 },
      ...taxes.map((i) => ({ title: i.name, amt: 0 })),
    ];
    let allTaxes = 0;
    const subTotal = cart.reduce((t, item) => {
      if (item.totalTax1) {
        list[1].amt += item.totalTax1;
        allTaxes += item.totalTax1;
      }
      if (item.totalTax2) {
        list[2].amt += item.totalTax2;
        allTaxes += item.totalTax2;
      }
      if (item.totalTax3) {
        list[3].amt += item.totalTax3;
        allTaxes += item.totalTax3;
      }
      return (t += item.subTotal);
    }, 0);
    list[0].amt = subTotal;

    return { finalBill: list, total: subTotal + allTaxes };
  }, [cart, taxes]);

  return (
    <>
      <h4 className="!text-lg text-primary font-semibold text-center border-b pb-2">
        My Cart
      </h4>
      <div className="border-b px-4 py-0.5 text-[15px] flex justify-between items-center ">
       <div className="flex max-sm:flex-col sm:gap-2 sm:items-center">
  <p className="font-bold">Order type :</p>
  <div className="flex gap-2 bg-gray-200 rounded-xl p-1">
  {!settings.ClosePickupOrder && (
    <button
      className={`${
        orderType === ORDER.TAKE_OUT ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
      } py-1 px-2 transition-colors duration-300 rounded-xl`}
      onClick={() => setOrderType(ORDER.TAKE_OUT)}
    >
      Take Out
    </button>
  )}
  {!settings.CloseDeliveryOrder && (
    <button
      className={`${
        orderType === ORDER.DELIVERY ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700'
      } py-2 px-2 transition-colors duration-300 rounded-xl`}
      onClick={() => setOrderType(ORDER.DELIVERY)}
    >
      Delivery
    </button>
  )}
</div>

</div>

        <Button className="!py-1" onClick={() => setDeleteDialog("ALL")}>
          Clear
        </Button>
      </div>
      <div className="py-2 max-h-[50vh] flex-1 overflow-y-auto">
        {cart.map((item, index) => (
          <Item
            item={item}
            index={index}
            onAction={handleCartAction}
            showDefaultToppings={settings?.DisplayDefaultToppings}
          />
        ))}
        {!cart.length && <p className="text-center">No Item Selected</p>}
      </div>

      <div className="px-5 border-t pt-2.5">
        {finalBill.map((i) => (
          <div className="flex justify-between border-b border-dashed pt-0.5">
            <p className="font-bold text-[14px]">{i.title}</p>
            <p className="font-bold text-[14px]">${i.amt.toFixed(2)}</p>
          </div>
        ))}
        {!!deliveryCharge && (
          <div className="flex justify-between border-b border-dashed pt-0.5">
            <p className="font-bold text-[14px]">Delivery Charge</p>
            <p className="font-bold text-[14px]">
              ${deliveryCharge.toFixed(2)}
            </p>
          </div>
        )}
        <div className="flex justify-between mt-1">
          <p className="font-bold text-[14px]">Total</p>
          <p className="font-bold text-[14px]">
            {" "}
            ${(total + deliveryCharge).toFixed(2)}
          </p>
        </div>
      </div>
      <Button
        disabled={!cart.length}
        className="mx-4 mt-1"
        onClick={placeOrder}
      >
        Place Order
      </Button>
      <Dialog onClose={() => setDeleteDialog(undefined)} open={!!deleteDialog}>
        <DialogTitle className="!font-bold">Remove from cart</DialogTitle>
        <DialogContent>
          <p>
            are you sure you want to remove{" "}
            {deleteDialog === "ALL"
              ? "All Items"
              : deleteDialog?.item.productName}
            {` `}
            from cart ?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            className="bg-white !text-primary"
            onClick={() => setDeleteDialog(undefined)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (deleteDialog === "ALL") {
                dispatch(removeAllFromCart());
              } else {
                dispatch(removeFromCart(deleteDialog.index));
              }
              setDeleteDialog(undefined);
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CartView;
