import React from "react";
import { OrderItem } from "utils/types";

const OrderRow = (props: OrderItem) => {
  return (
    <div className="flex border-2 rounded-lg px-4 py-2">
      <p className="flex-1">{props.orderID}</p>
      <p className="flex-1">{props.orderType}</p>
      <p className="flex-1">{props.onDate}</p>
      <p className="flex-1">{props.onTime}</p>
      <p className="flex-1">{props.paymentType || "--"}</p>
      <p className="flex-1">{props.orderTotal.toFixed(2)}</p>
    </div>
  );
};

export default OrderRow;
