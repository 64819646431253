import Axios from "axios";

export const baseURL =
  (window as any)?.config?.REACT_APP_API_URL ||
  process.env.REACT_APP_API_URL ||
  "http://localhost:5000";

const api = Axios.create({ baseURL });

api.interceptors.response.use(
  async (response) => {
    if (
      response?.status === 201 ||
      response?.status === 200 ||
      response?.status === 204
    ) {
      return response;
    } else if (response?.status === 204) {
      return Promise.reject("");
    } else {
      return Promise.reject(response ?? "Something Went Wrong");
    }
  },
  async (error) => {
    if (error?.response?.status === 401) {
      // window.location.reload();
      return Promise.reject(error?.response?.data || "Authorization Error");
    }

    if (error?.response?.status > 400) {
      // Need to change the text here
      return Promise.reject(error?.response?.data || "Authorization Error");
    }

    return Promise.reject(
      error?.response?.data?.message ??
        error?.toString() ??
        "Something Went Wrong"
    );
  }
);

export default api;
