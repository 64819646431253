import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { toggleDrawer } from "store/slices/main.slice";
type Props = {};

const Sidebar = (props: Props) => {
  const sideBarMenu = useAppSelector(({ main }) => main.sideBarMenu);
  const showDrawer = useAppSelector(({ main }) => main.showDrawer);
  const [searchParams, setSearchParams] = useSearchParams();
  const isTablet = useMediaQuery("(min-width:768px)");
  const dispatch = useAppDispatch();

  const product = useMemo(
    () => +(searchParams.get("product") || 0),
    [searchParams]
  );

  return (
    <>
      {!isTablet && showDrawer && (
        <div
          className="absolute top-0 bottom-0 left-0 right-0 bg-black/30 z-10"
          onClick={() => dispatch(toggleDrawer())}
        />
      )}
      <motion.div
        className="w-[180px] h-full border-r border-primary bg-white px-2 pt-3 max-md:absolute z-20 max-md:shadow-2xl overflow-auto"
        // key={showDrawer}
        initial={{ translateX: !isTablet ? -180 : 0 }}
        animate={{ translateX: showDrawer || isTablet ? 0 : -180 }}
        transition={{ duration: 0.4 }}
      >
        {sideBarMenu.map(({ id, categoryDescription }) => (
          <div
            className={`capitalize transition-all duration-300 py-1.5 px-3 mt-1 rounded-lg cursor-pointer font-medium ${
              product === id
                ? "bg-primary text-light-text"
                : "hover:bg-primary/20"
            }`}
            onClick={() => {
              let search = searchParams;
              search.set("product", id.toString());
              setSearchParams(search);
            }}
          >
            {categoryDescription.toLowerCase()}
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default Sidebar;
