import { Dialog } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useState } from "react";
import { toggleCart } from "store/slices/main.slice";
import CartView from "./Cart";
import Details from "./Details";
import CheckoutView from "./Checkout";
import PaymentView from "./Payment";

type Props = {};

const CartDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const [stage, setStage] = useState("CART");

  const showCart = useAppSelector(({ main }) => main.showCart);

  return (
    <Dialog
      onClose={() => {
        dispatch(toggleCart());
      }}
      open={showCart}
      maxWidth="lg"
    >
      <div className="min-w-[80vw] md:min-w-[600px] min-h-[70vh] py-2.5 flex flex-col">
        {stage === "CART" && <CartView setStage={setStage} />}
        {stage === "DETAILS" && <Details setStage={setStage} />}
        {stage === "CHECKOUT" && <CheckoutView setStage={setStage} />}
        {stage === "PAYMENT" && <PaymentView setStage={setStage} />}
      </div>
    </Dialog>
  );
};

export default CartDialog;
