import {
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  SelectProps,
} from "@mui/material";
import React from "react";

type Props = SelectProps & {
  onValueChange: (v: any) => void;
};

const Select = ({ onValueChange, label, ...rest }: Props) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <MUISelect
        label={label}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        {...rest}
        onChange={(e) => onValueChange(e.target.value)}
      />
    </FormControl>
  );
};

export default Select;
