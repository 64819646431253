// import Loading from "components/common/Loading";
// import Product from "components/user/Product";
// import ProductPopUp from "components/user/ProductPopUp";
// import api from "config/api";
// import { useAppDispatch, useAppSelector } from "hooks/store";
// import React, { useEffect, useMemo, useState } from "react";
// import { useSearchParams } from "react-router-dom";
// import { setPizzaList, setSelected } from "store/slices/main.slice";

// type Props = {
//   isAdmin: boolean;
// };

// const Listing = (props: Props) => {
//   const dispatch = useAppDispatch();
//   const [searchParams] = useSearchParams();
//   const [products, setproducts] = useState<any[]>([]);
//   const [loading, setLoading] = useState(false);
//   const pizzaList = useAppSelector(({ main }) => main.pizzaList);

//   const fetchData = (id: string | null, setState: boolean = false) => {
//     if (id) {
//       setLoading(true);
//       setproducts([]);
//       api
//         .get("/api/Product/ByCategoryId?categoryId=" + id)
//         .then(({ data }) => {
//           if (!pizzaList.length && id !== "7") {
//             fetchData("7");
//           }

//           if (id === "7") {
//             dispatch(setPizzaList(data));
//           }
//           if (setState) {
//             setproducts(data);
//           }
//         })
//         .finally(() => setLoading(false));
//     }
//   };

//   const product = useMemo(
//     () => searchParams.get("product"),
//     [searchParams.get("product")]
//   );
//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       fetchData(product, true);
//     }, 200);
//     return () => clearTimeout(timeout);
//   }, [product]);

//   return (
//     <div className="h-full relative z-0">
//       <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 overflow-y-auto items-start max-h-full p-4 ">
//         {products.map((item) => (
//           <Product
//             {...item}
//             btnText="Add to cart"
//             onClick={() => dispatch(setSelected(item))}
//           />
//         ))}
//       </div>
      
//       {loading && <div className="h-44">
//         <Loading /> </div>}
//       <ProductPopUp />
//     </div>
//   );
// };

// export default Listing;

import Loading from "components/common/Loading";
import Product from "components/user/Product";
import ProductPopUp from "components/user/ProductPopUp";
import api from "config/api";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { setPizzaList, setSelected } from "store/slices/main.slice";
import EditProductDialog from "components/admin/EditProductDialog";

type Props = {
};

const Listing = (props: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [products, setproducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const pizzaList = useAppSelector(({ main }) => main.pizzaList);
  const [selectProduct, setSelectProduct] = useState<any>(undefined);

  const fetchData = (id: string | null, setState: boolean = false) => {
    if (id) {
      setLoading(true);
      setproducts([]);
      api
        .get("/api/Product/ByCategoryId?categoryId=" + id)
        .then(({ data }) => {
          if (!pizzaList.length && id !== "7") {
            fetchData("7");
          }

          if (id === "7") {
            dispatch(setPizzaList(data));
          }
          if (setState) {
            setproducts(data);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const product = useMemo(
    () => searchParams.get("product"),
    [searchParams.get("product")]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData(product, true);
    }, 200);
    return () => clearTimeout(timeout);
  }, [product]);

  return (
    <div className="h-full relative z-0">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 overflow-y-auto items-start max-h-full p-4 ">
        {products.map((item) => (
          <Product
            {...item}
            btnText={window.location.pathname.includes("admin") ? "Edit":"Add to cart"}
            onClick={() => {
              dispatch(setSelected(item));
              setSelectProduct(item); // Set the selected product for editing
            }}
          />
        ))}
      </div>

      {loading && <div className="h-44"><Loading /></div>}

      {/* Conditional rendering based on isAdmin */}
      {window.location.pathname.includes("admin") ? (
        <EditProductDialog
          selected={selectProduct}
          setSelected={setSelectProduct}
          onComplete={() => {
            setSelectProduct(undefined);
            fetchData(product, true); 
          }}
        />
      ) : (
        <ProductPopUp />
      )}
    </div>
  );
};

export default Listing;
