import { useEffect, useRef } from "react";

type Props = {
  value: number | string;
  onSubmit: (v: string) => void;
};

const PayPal = ({ value, onSubmit }: Props) => {
  const paypalRef = useRef<any>();

  useEffect(() => {
    const paypalButton = (window as any)?.paypal?.Buttons({
      style: {
        height: 35,
      },
      createOrder: (data: any, actions: any, err: any) => {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [
            {
              description: "cool looking table",
              amount: {
                currency_code: "CAD",
                value,
              },
            },
          ],
        });
      },
      onApprove: async (data: any, actions: any) => {
        const order = await actions.order.capture();
        onSubmit("PAY_PAL");
      },
      onError: (err: any) => {
        console.error(err);
      },
    });

    // Render the PayPal button
    paypalButton?.render(paypalRef.current);

    return () => paypalButton?.close();
  }, [value]);

  return (
    <div className="flex justify-center mt-2 px-4">
      <div ref={paypalRef} className="w-full"></div>
    </div>
  );
};

export default PayPal;
