import Loading from "components/common/Loading";
import CartDialog from "components/user/cart/Index";
import UserDetailPopUp from "components/user/UserDetailPopUp";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "screens/auth/Login";
import Register from "screens/auth/Register";
import { fetchInitialData } from "store/slices/main.slice";
import MainPage from "./MainPage";
import MyOrder from "./MyOrder";
import ShopClosed from "./ShopClosed";

type Props = {};

const UserContainer = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ main }) => main.loading);
  const OnlineOrderingStatus = useAppSelector(
    ({ main }) => main.settings.OnlineOrderingStatus
  );

  useEffect(() => {
    dispatch(fetchInitialData(navigate));
  }, []);

  if (loading) return <Loading />;

  if (OnlineOrderingStatus) return <ShopClosed />;
  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/myOrders" element={<MyOrder />} />
      </Routes>
      <CartDialog />
      <UserDetailPopUp />
    </>
  );
};

export default UserContainer;
