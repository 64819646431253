import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

type Props = TextFieldProps & {
  onValueChange?: (v: any) => void;
};

const Input = ({ onValueChange, ...rest }: Props) => {
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      {...rest}
      onChange={(e) => onValueChange?.(e.target.value)}
    />
  );
};

export default Input;
