import { Popover } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Button from "components/common/Button";
import { setAllCartData, setUserData, toggleUserDetailModel } from "store/slices/main.slice";

type Props = {};

const LoginButton = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPopup, setshowPopup] = useState(null);
  const userData = useAppSelector(({ main }) => main.userData);
  const id = showPopup ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        className="bg-white !text-dark-text flex gap-1 items-center !px-3"
        onClick={(event: any) => {
          if (userData) {
            setshowPopup(event.currentTarget);
          } else {
            navigate("/login");
          }
        }}
      >
        {userData ? (
          <>
            <PersonIcon className="!text-[16px]" />
            {userData.firstName}
          </>
        ) : (
          "Login"
        )}
      </Button>
      <Popover
        id={id}
        open={!!showPopup}
        anchorEl={showPopup}
        onClose={() => setshowPopup(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="flex flex-col py-2 px-4">
          <div
            className="text-primary font-semibold text-sm border-b border-dashed px-2 py-1 cursor-pointer"
            onClick={() => {
              navigate("/myOrders");
            }}
          >
            My Orders
          </div>
          <div
            className="text-primary font-semibold text-sm border-b border-dashed px-2 py-1 cursor-pointer"
            onClick={() => {
              dispatch(toggleUserDetailModel());
              setshowPopup(null);
            }}
          >
            Customer Details
          </div>
          <div
            className="text-primary font-semibold text-sm px-2 py-1 cursor-pointer"
            onClick={() => {
              dispatch(setUserData(undefined));
              dispatch(setAllCartData([]));
              setshowPopup(null);
              setTimeout(() => {
                window.location.reload()
              },500)
            }}
          >
            Log Out
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default LoginButton;
