import { CircularProgress, Dialog, Tab, Tabs } from "@mui/material";
import Button from "components/common/Button";
import ModificationSelection from "components/common/ModificationSelection";
import api from "config/api";
import { useEffect, useMemo, useState } from "react";
import { ModifierItemType } from "utils/types";

type Props = {
  open: any;
  setOpen: (v: any) => void;
  onSubmit: (v: any) => void;
};

const ModificationPopUp = ({ open, setOpen, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState<ModifierItemType[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [config, setConfig] = useState({});
  const [defaultToppings, setDefaulToppings] = useState<any>({});

  useEffect(() => {
    if (open) {
      setLoading(true);
      api
        .get(`/api/Modifier/ModifierCategories?${new URLSearchParams(open)}`)
        .then(({ data }) => {
          setSelectedTab(data[0].modifierId);
          setTabs(data);
          const temp: any = {};
          const list = data.reduce((obj: any, itm: any) => {
            const filtered = itm.modifiers.filter((i: any) => i.defaultTopping);
            temp[itm.modifierId] = filtered.length;
            return {
              ...obj,
              [itm.modifierId]: filtered,
            };
          }, {});
          if (open?.modifications) {
            setConfig(open.modifications);
          } else {
            setConfig(list);
          }
          setDefaulToppings(temp);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [open]);

  const selectOptions = useMemo(
    () => tabs.find((i) => i.modifierId === selectedTab)?.modifiers || [],
    [tabs, selectedTab]
  );

  return (
    <Dialog onClose={() => setOpen(undefined)} open={!!open} maxWidth="lg">
      <div className="min-w-[80vw] md:min-w-[600px]">
        <h4 className="!text-lg text-primary font-semibold text-center border-b mt-5 pb-2">
          CUSTOMIZE YOUR ORDER
        </h4>
        {!!tabs.length && (
          <div className="px-2 border-b">
            <Tabs
              value={selectedTab}
              onChange={(_, v) => setSelectedTab(v)}
              aria-label="basic tabs example"
            >
              {tabs.map((i) => (
                <Tab value={i.modifierId} label={i.modifierName} />
              ))}
            </Tabs>
          </div>
        )}

        <div className="h-[60vh] flex flex-col">
          {loading ? (
            <CircularProgress className="self-center mt-[10%]" />
          ) : (
            <ModificationSelection
              label="pizzaModifierName"
              selectedTab={selectedTab}
              config={config}
              setConfig={setConfig}
              options={selectOptions}
            />
          )}
        </div>
        <div className="flex justify-between p-4 border-t">
          <Button onClick={() => setOpen(undefined)}>Cancel</Button>
          <Button
            onClick={() => {
              onSubmit(
                Object.entries(config).reduce((obj: any, [key, arr]: any) => {
                  const array = structuredClone(arr).sort(
                    (a: any, b: any) => b.defaultTopping - a.defaultTopping
                  );

                  obj[key] = array.map((value: any, i: number) => {
                    if (!value.defaultTopping && defaultToppings[key] > i) {
                      value.newDefaultTopping = 1;
                    } else {
                      delete value.newDefaultTopping;
                    }
                    return value;
                  });
                  return obj;
                }, {})
              );
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModificationPopUp;
