import { Edit } from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material";
import TimeSettingDialog from "components/admin/TimeSettingDialog";
import api from "config/api";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { ORDER } from "utils/constants";
import { TimeItem } from "utils/types";

type DataType = {
  TakeOut: TimeItem[];
  Delivery: TimeItem[];
};

const TimeSetting = () => {
  const [selectedTab, setSelectedTab] = useState<keyof DataType>(
    ORDER.TAKE_OUT
  );
  const [data, setData] = useState<DataType>({ TakeOut: [], Delivery: [] });
  const [selectedData, setSelectedData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    api
      .get("/api/RestTime/GetResttimes")
      .then(({ data }) => {
        setData(
          data.reduce(
            (obj: any, item: any) => ({
              ...obj,
              [item.timOrdtype]: [...(obj[item.timOrdtype] || []), item],
            }),
            {}
          )
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full flex flex-col p-4">
      <Tabs
        value={selectedTab}
        onChange={(_, i) => setSelectedTab(i)}
        aria-label="order timing tabs"
        className="self-center"
      >
        {Object.keys(data).map((i) => (
          <Tab label={i} value={i} />
        ))}
      </Tabs>
      <div className="bg-white rounded-lg mt-4 time-setting-table overflow-hidden">
        <table className="border-collapse w-full">
          <thead className="bg-primary text-white py-2">
            <tr>
              <th className="text-start py-2 pl-4">Day</th>
              <th>Enabled</th>
              <th>Open 24hr</th>
              <th>From</th>
              <th>TO</th>
              <th className="py-2 pr-4">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {data?.[selectedTab]?.map((item) => (
              <tr className="hover:bg-gray-200">
                <td className="pl-4 py-2.5">{item.timDay}</td>
                <td className="text-center">{item.timEnable ? "YES" : "NO"}</td>
                <td className="text-center">{item.timIs24Hr ? "YES" : "NO"}</td>
                <td className="text-center">
                  {format(new Date(item.timFrom), "MM/dd/yyyy HH:mm")}
                </td>
                <td className="text-center">
                  {format(new Date(item.timTo), "MM/dd/yyyy HH:mm")}
                </td>
                <td className="pr-4 py-2 cursor-pointer">
                  <div
                    className="flex justify-center"
                    onClick={() => setSelectedData(item)}
                  >
                    <Edit className="!text-[20px]" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && !data?.[selectedTab].length && (
          <div className="bg-white">
            {Array.from({ length: 7 }).map(() => (
              <div className="h-11 animate-pulse bg-gray-300 border-b"></div>
            ))}
          </div>
        )}
      </div>
      <TimeSettingDialog
        options={data}
        selected={selectedData}
        onClose={(v) => {
          if (v) {
            fetchData();
          }
          setSelectedData(undefined);
        }}
      />
    </div>
  );
};

export default TimeSetting;
