import { createTheme } from "@mui/material";

const cssVar = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

export const theme = createTheme({
  palette: {
    primary: { main: `rgb(${cssVar("--color-primary")})` },
  },
  typography: {
    allVariants: { color: `rgb(${cssVar("--color-dark-text")})` },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          color: `rgb(${cssVar("--color-dark-text")})`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: { color: `rgb(${cssVar("--color-dark-text")})` },
      },
    },
  },
});
