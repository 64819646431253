import Loading from "components/common/Loading";
import OrderCard from "components/common/OrderCard";
import ToolBar from "components/user/ToolBar";
import api from "config/api";
import { useAppSelector } from "hooks/store";
import { useEffect, useState } from "react";
import { OrderItem } from "utils/types";
type Props = {};

const MyOrder = (props: Props) => {
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const { userData, taxes } = useAppSelector(({ main }) => main);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData?.clientId) {
      setLoading(true);
      api
        .get(`/api/OrderSubmition/GetOrders?customerID=${userData.clientId}`)
        .then(({ data }) => setOrders(data))
        .finally(() => setLoading(false));
    }
  }, [userData]);

  return (
    <div className="min-h-screen">
      <ToolBar />
      {loading && <Loading />}
      <div className="px-3 pt-3">
        <h1 className="text-2xl font-bold">My All Orders</h1>
        <div className="max-h-[calc(100vh-115px)] overflow-y-auto mt-2 flex flex-col gap-2 no-scrollbar">
          {orders.map((item) => (
            <OrderCard {...item} taxData={taxes} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrder;
