import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import Button from "components/common/Button";
import Input from "components/common/Input";
import OrderCard from "components/common/OrderCard";
import OrderRow from "components/common/OrderRow";
import api from "config/api";
import { format, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { OrderItem } from "utils/types";

type Props = {};

const AllOrders = (props: Props) => {
  const [mode, setMode] = useState("ROW");
  const [data, setData] = useState<OrderItem[]>([]);
  const [taxData, setTaxData] = useState<any>({});
  const [dates, setDates] = useState({
    fromDate: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
    toDate: format(new Date(), "yyyy-MM-dd"),
  });

  const fetchData = () => {
    api
      .get(
        `/api/OrderSubmition/GetSearchOrders?fromDate=${format(
          new Date(dates.fromDate),
          "MM/d/yyyy"
        )}&toDate=${format(new Date(dates.toDate), "MM/d/yyyy")}`
      )
      .then(({ data }) => setData(data))
      .catch((e) => {
        console.error("ERROR :", e);
      });
  };

  useEffect(() => {
    fetchData();
    api.get("/api/initialData").then(({ data }) => {
      if (data?.taxes) setTaxData(data.taxes);
    });
  }, []);

  return (
    <div className="p-4 w-full">
      <div className=" w-full flex justify-between items-center max-sm:flex-col max-sm:items-end gap-2 border-b-2 pb-2">
        <div className="border-2 rounded-lg flex  overflow-hidden cursor-pointer">
          <div
            className={`py-1 px-2 ${
              mode === "ROW" ? "bg-primary !text-white" : ""
            }`}
            onClick={() => setMode("ROW")}
          >
            <TableRowsRoundedIcon />
          </div>
          <div
            className={`py-1 px-2 ${
              mode === "GRID" ? "bg-primary !text-white" : ""
            }`}
            onClick={() => setMode("GRID")}
          >
            <GridViewRoundedIcon />
          </div>
        </div>
        <div className="flex justify-end items-center gap-3">
          <b>From :</b>
          <Input
            value={dates.fromDate}
            size="small"
            type="date"
            placeholder="From :"
            onValueChange={(fromDate) => setDates((e) => ({ ...e, fromDate }))}
          />
          <b>To :</b>
          <Input
            value={dates.toDate}
            size="small"
            type="date"
            placeholder="To :"
            onValueChange={(toDate) => setDates((e) => ({ ...e, toDate }))}
          />
          <Button onClick={fetchData}>Search</Button>
        </div>
      </div>
      <div className="max-h-[calc(100vh-190px)] md:max-h-[calc(100vh-140px)] md:max-w-[calc(100vw-185px)] overflow-auto mt-2 no-scrollbar">
        {mode === "GRID" ? (
          <div className="flex flex-col gap-2 ">
            {data.map((item) => (
              <OrderCard {...item} taxData={taxData} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-2 min-w-[700px]  md:min-w-[800px]">
            <div className="bg-primary rounded-lg flex px-4 py-2 sticky top-0">
              <b className="flex-1 text-white">Order</b>
              <b className="flex-1 text-white">Type</b>
              <b className="flex-1 text-white">Date</b>
              <b className="flex-1 text-white">Time</b>
              <b className="flex-1 text-white">Payment</b>
              <b className="flex-1 text-white">Price</b>
            </div>
            {data.map((item) => (
              <OrderRow {...item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AllOrders;
