import Button from "components/common/Button";
import Input from "components/common/Input";
import api from "config/api";
import { useState } from "react";
import { toast } from "react-toastify";

type Props = {
  value: number;
  onSubmit: (v: string) => void;
};

const inititalData = {
  nameOnCard: "",
  cardNumber: "",
  expiryDate: "",
  cardCVC: "",
};
const Clover = ({ value, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(inititalData);

  const handleSubmit = () => {
    toast.dismiss();
    if (!data.nameOnCard) {
      toast.error("Invalid Cardholder Name");
      return;
    }
    if (data.cardNumber.length !== 16) {
      toast.error("Invalid Card Number");
      return;
    }
    if (
      !data.expiryDate ||
      !/\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/.test(data.expiryDate)
    ) {
      toast.error("Invalid Expiry Date");
      return;
    }
    if (!/^\d+$/.test(data.cardCVC) || data.cardCVC.length !== 3) {
      toast.error("Invalid Card CVV");
      return;
    }
    setLoading(true);
    api
      .post("/api/Clover", { ...data, amount: value })
      .then(({ data }) => {
        onSubmit("CLOVER");
      })
      .catch(() => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col gap-2.5 mt-2 px-4">
      <Input
        size="small"
        type="text"
        required
        label="Cardholder Name"
        value={data.nameOnCard}
        onValueChange={(nameOnCard) =>
          setData((e) => ({ ...e, nameOnCard: nameOnCard.toUpperCase() }))
        }
      />
      <Input
        size="small"
        type="text"
        required
        label="Card Number"
        placeholder="0000 0000 0000 0000"
        value={data.cardNumber}
        onValueChange={(cardNumber) =>
          setData((e) => ({
            ...e,
            cardNumber: isNaN(+cardNumber) ? e.cardNumber : cardNumber,
          }))
        }
      />
      <div className="flex gap-2">
        <Input
          size="small"
          type="text"
          required
          className="flex-1"
          placeholder="MM/YYYY"
          label="Expiration"
          value={data.expiryDate}
          onValueChange={(expiryDate) => setData((e) => ({ ...e, expiryDate }))}
        />
        <Input
          size="small"
          type="text"
          required
          label="CVV"
          className="flex-1"
          value={data.cardCVC}
          onValueChange={(cardCVC) => setData((e) => ({ ...e, cardCVC }))}
        />
      </div>
      <Button disabled={loading} onClick={handleSubmit}>
        PAY
      </Button>
    </div>
  );
};

export default Clover;
