import { useAppDispatch, useAppSelector } from "hooks/store";
import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { toggleDrawer } from "store/slices/main.slice";

type Props = {};

const HorizontalMenu = (props: Props) => {
  const sideBarMenu = useAppSelector(({ main }) => main.sideBarMenu);
  const [searchParams, setSearchParams] = useSearchParams();
  const product = useMemo(
    () => +(searchParams.get("product") || 0),
    [searchParams]
  );

  return (
    <div className="flex space-x-4 p-3 bg-white border-b border-primary overflow-x-auto">
    {sideBarMenu.map(({ id, categoryDescription }) => (
      <div
        key={id}
        className={`capitalize transition-all duration-300 py-1.5 px-3 rounded-lg cursor-pointer font-medium ${
          product === id
            ? "bg-primary text-light-text"
            : "hover:bg-primary/20"
        } flex-shrink-0 `}
        onClick={() => {
          let search = searchParams;
          search.set("product", id.toString());
          setSearchParams(search);
        }}
      >
        <span className="block overflow-hidden overflow-ellipsis whitespace-normal line-clamp-2">
          {categoryDescription.toLowerCase()}
        </span>
      </div>
    ))}
  </div>
  
  
  );
};

export default HorizontalMenu;
