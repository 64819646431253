import React, { useState } from "react";
import logo from "assets/images/login-image.png";
import Input from "components/common/Input";
import Button from "components/common/Button";
import api from "config/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/store";
import { setUserData } from "store/slices/main.slice";
import GoogleAddress from "components/common/GoogleAddress";
type Props = {};

const Register = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    confirmPassword: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNo: "",
    address: "",
    address1: "",
  });

  const handleSubmit = () => {
    toast.dismiss();
    if (!data.firstName || !data.lastName) {
      toast.error("First Name & Last Name is required");
      return;
    }

    if (!/^\d{10}$/.test(data.phoneNo)) {
      toast.error("Invalid Phone Number");
      return;
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        data.email
      )
    ) {
      toast.error("Invalid Email");
      return;
    }

    if (data.password.length <= 3) {
      toast.error("Password must be greater than 3 characters");
      return;
    }

    if (data.password !== data.confirmPassword) {
      toast.error("Confirm password not match");
      return;
    }
    setLoading(true);
    api
      .post(`/api/Customer`, data)
      .then(({ data }) => {
        dispatch(setUserData(data));
        toast.success("User created Successfully");
        navigate("/login");
      })
      .catch((e) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="bg-background h-screen flex justify-center items-center">
      <div className="bg-white rounded-xl overflow-hidden flex gap-2 py-8 lg:py-2 px-6">
        <div className="flex max-md:hidden">
          <img src={logo} className="h-[200px] lg:h-[350px]" />
        </div>
        <div className="flex flex-col gap-2 justify-center items-center px-8">
          <h6 className="font-bold text-primary text-xl">Create new Account</h6>
          <div className="flex max-sm:flex-col gap-3">
            <Input
              size="small"
              label="First Name"
              variant="standard"
              value={data.firstName}
              sx={{ minWidth: 230 }}
              onValueChange={(firstName) =>
                setData((e) => ({ ...e, firstName }))
              }
            />
            <Input
              size="small"
              label="Last Name"
              variant="standard"
              value={data.lastName}
              sx={{ minWidth: 230 }}
              onValueChange={(lastName) => setData((e) => ({ ...e, lastName }))}
            />
          </div>
          <div className="flex max-sm:flex-col gap-3">
            <Input
              size="small"
              label="Phone Number"
              variant="standard"
              value={data.phoneNo}
              sx={{ minWidth: 230 }}
              onValueChange={(phoneNo) => setData((e) => ({ ...e, phoneNo }))}
            />
            <Input
              size="small"
              label="Email"
              variant="standard"
              value={data.email}
              sx={{ minWidth: 230 }}
              onValueChange={(email) => setData((e) => ({ ...e, email }))}
            />
          </div>
          <div className="flex max-sm:flex-col gap-3">
            <Input
              size="small"
              label="Password"
              type="password"
              variant="standard"
              value={data.password}
              sx={{ minWidth: 230 }}
              onValueChange={(password) => setData((e) => ({ ...e, password }))}
            />
            <Input
              size="small"
              label="Confirm Password"
              type="password"
              variant="standard"
              value={data.confirmPassword}
              sx={{ minWidth: 230 }}
              onValueChange={(confirmPassword) =>
                setData((e) => ({ ...e, confirmPassword }))
              }
            />
          </div>
          <div className="flex max-sm:flex-col gap-3 mt-1">
            <GoogleAddress
              className="min-w-[230px]"
              lable="Main Address"
              value={data.address}
              onChange={(address) => setData((e) => ({ ...e, address }))}
            />
            <GoogleAddress
              className="min-w-[230px]"
              lable="Secondary (Another Address)"
              value={data.address1}
              onChange={(address1) => setData((e) => ({ ...e, address1 }))}
            />
          </div>

          <Button
            disabled={loading}
            className="mt-6 w-full"
            onClick={handleSubmit}
          >
            REGISTER
          </Button>
          <p className="text-[13px]">
            Already have an account?
            <a href="/login" className="font-bold text-primary">
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
{
}

export default Register;
