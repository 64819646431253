import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialStateType = {
  adminData: any;
  showDrawer: boolean;
};

const initialState: InitialStateType = {
  adminData: undefined,
  showDrawer: false,
};

export const adminSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setAdminData: (state, action: PayloadAction<any>) => {
      state.adminData = action.payload;
    },
    toggleDrawer: (state) => {
      state.showDrawer = !state.showDrawer;
    },
  },
});

export const { setAdminData, toggleDrawer } = adminSlice.actions;
export default adminSlice.reducer;
