import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { OrderItem } from "utils/types";

type OrderCardType = OrderItem & {
  taxData?: any;
};
const OrderCard = (item: OrderCardType) => {
  const [showProducts, setshowProducts] = useState(false);
  return (
    <div
      className="w-full bg-primary/10 py-2 px-4 rounded-lg cursor-pointer select-none"
      onClick={() => setshowProducts(!showProducts)}
    >
      <div className="flex justify-between border-b border-primary/50 px-2 pb-1">
        <p>
          <b>Order ID : </b>
          {item.orderID}
        </p>
        <p>
          <b>Total : </b>${item.orderTotal.toFixed(2)}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <div className="flex-1">
          <div className="flex-1 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-y-0.5 py-0.5 px-2">
            <p>
              <b>Order Type : </b>
              {item.orderType}
            </p>
            <p>
              <b>Payment Method : </b>
              {item.paymentType}
            </p>
            <p>
              <b>Note : </b>
              {item.notes || "--"}
            </p>
            <p>
              <b>Date : </b>
              {item.onDate}
            </p>
            <p>
              <b>Time : </b>
              {item.onTime}
            </p>

            <p>
              <b>{item?.taxData?.[0]?.name || "Tax 1"} : </b>${" "}
              {(item.totalTax1 || 0).toFixed(2)}
            </p>
            <p>
              <b>{item?.taxData?.[1]?.name || "Tax 2"} : </b>$
              {(item.totalTax2 || 0).toFixed(2)}
            </p>
            <p>
              <b>{item?.taxData?.[2]?.name || "Tax 3"} : </b>$
              {(item.totalTax3 || 0).toFixed(2)}
            </p>
            <p>
              <b>Tip : </b>${item.tip}
            </p>
            <p>
              <b>Sub Total : </b>${item.subTotal}
            </p>
          </div>
          <AnimatePresence>
            <motion.div
              className="overflow-hidden"
              initial={{ height: 0 }}
              animate={{ height: showProducts ? "auto" : 0 }}
            >
              <div className="py-1 border border-primary rounded-lg my-1">
                <h2 className="px-3 font-bold pb-1">Product Details</h2>
                <div className="flex px-3 border-y border-primary font-bold text-[14px] py-1">
                  <p className="flex-[2] sm:flex-1">Product Code</p>
                  <p className="flex-1 ">Quantity</p>
                  <p className="flex-1 max-2md:hidden">
                    {item?.taxData?.[0]?.name || "Tax 1"}
                  </p>
                  <p className="flex-1 max-2md:hidden">
                    {item?.taxData?.[1]?.name || "Tax 2"}
                  </p>
                  <p className="flex-1 max-2md:hidden">
                    {item?.taxData?.[2]?.name || "Tax 3"}
                  </p>
                  <p className="flex-1">Total</p>
                </div>
                <div className="flex flex-col divide-y divide-dashed divide-primary mx-3 ">
                  {item.orderDetail.map((subItem) => (
                    <div className="flex text-[14px] py-0.5">
                      <p className="flex-[2] sm:flex-1">
                        {subItem.productCode}
                      </p>
                      <p className="flex-1">{subItem.quantity}</p>
                      <p className="flex-1 max-2md:hidden">
                        ${subItem.tax1.toFixed(2)}
                      </p>
                      <p className="flex-1 max-2md:hidden">
                        ${subItem.tax2.toFixed(2)}
                      </p>
                      <p className="flex-1 max-2md:hidden">
                        ${subItem.tax3.toFixed(2)}
                      </p>
                      <p className="flex-1">${subItem.totalValue}</p>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        <div>
          {showProducts ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
