import { Checkbox } from "@mui/material";
import React, { useMemo, useState } from "react";

type Props = {
  config: any;
  setConfig: (v: any) => void;
  selectedTab: number;
  label: string;
  options: any[];
};

const ModificationSelection = ({
  label,
  options,
  config,
  setConfig,
  selectedTab,
}: Props) => {
  const selected = useMemo(
    () => config?.[selectedTab]?.map((i: any) => i.pizzaModifierId),
    [config, selectedTab]
  );

  const onCheck = (item: any) => {
    const found = config[selectedTab].findIndex(
      (i: any) => i.pizzaModifierId === item.pizzaModifierId
    );

    if (found >= 0) {
      setConfig((e: any) => ({
        ...e,
        [selectedTab]: e[selectedTab].filter(
          (_: any, i: number) => i !== found
        ),
      }));
    } else {
      setConfig((e: any) => ({
        ...e,
        [selectedTab]: [...e[selectedTab], item],
      }));
    }
  };
  return (
    <div className="px-3 py-2.5 h-[60vh] overflow-y-auto flex flex-col gap-1">
      {options.map((item, index) => (
        <div
          className="flex gap-1 items-center border rounded-md py-1 px-1.5"
          key={index}
        >
          <Checkbox
            key={index}
            sx={{ p: 0 }}
            checked={selected.includes(item.pizzaModifierId) || false}
            onChange={() => onCheck(item)}
          />
          <p>
            {item[label]} [${item.pizzaModifierPrice}]
          </p>
        </div>
      ))}
    </div>
  );
};

export default ModificationSelection;
