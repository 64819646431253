import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch } from "hooks/store";
import { useNavigate } from "react-router-dom";
import { toggleDrawer } from "store/slices/main.slice";

const ToolBar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="h-[60px] bg-primary px-[20px] flex items-center justify-between shadow-[0px_10px_10px_5px_#00000024] z-10 ">
      <div className="flex gap-2 items-center">
        <ArrowBackIcon
          className="max-md:hidden !text-white"
          onClick={() => navigate(-1)}
        />
        <div className="md:hidden" onClick={() => dispatch(toggleDrawer())}>
          <MenuIcon className="text-light-text cursor-pointer -ml-2" />
        </div>
        <h1 className="text-xl font-bold text-light-text">RANGER POS ADMIN</h1>
      </div>
      <div className="flex gap-2">{/* <LoginButton /> */}</div>
    </div>
  );
};

export default ToolBar;
