import React, { useEffect, useMemo, useRef, useState } from "react";
import { throttle } from "lodash";
import { Autocomplete, Box, Grid, Typography } from "@mui/material";
import Input from "./Input";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";

const GOOGLE_MAPS_API_KEY = "AIzaSyBVNKPywiKIxMm9pXEu9MI6_FYThIyUpbg";

type Props = {
  className?: string;
  lable?: string;
  value: string;
  disabled?: boolean;
  onChange: (v: string) => void;
};

function loadScript(src: any, position: any, id: any) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const GoogleAddress = ({
  value,
  className,
  lable,
  onChange,
  disabled,
}: Props) => {
  const [options, setOptions] = useState<any[]>([]);
  const autocompleteService = useRef<any>(null);

  const fetch = useMemo(
    () =>
      throttle((request: any, callback: any) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 200),
    [autocompleteService.current]
  );

  if (typeof window !== "undefined") {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
  }

  useEffect(() => {
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
  }, []);

  useEffect(() => {
    fetch({ input: value }, (results: any[]) => {
      setOptions(results || []);
    });
  }, [fetch, value]);

  return (
    <Autocomplete
      id="google-map-demo"
      size="small"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.description
      }
      filterOptions={(x) => x}
      options={options}
      disabled={disabled}
      autoComplete
      includeInputInList
      filterSelectedOptions
      className={className}
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: any) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange(newValue?.description || newValue);
      }}
      renderInput={(params) =>
        lable ? (
          <Input
            {...params}
            label={lable ? lable : "Address"}
            onValueChange={onChange}
          />
        ) : (
          <Input {...params} onValueChange={onChange} />
        )
      }
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part: any, index: any) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GoogleAddress;
