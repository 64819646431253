import { Checkbox } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type Props = {
  selected: Object;
  setSelected: any;
  moveToNextStep: any;
  option?: {
    mandatoryLevel: number;
    nameLevel: string;
    qtyLevel: number;
    data: any[];
  };
};

const defaultParams = {
  data: [],
  nameLevel: "",
  qtyLevel: Infinity,
  mandatoryLevel: 0,
};

const Item = ({
  selected,
  setSelected,
  item,
  index,
  qtyLevel,
  moveToNextStep,
}: any) => {
  const [showSubLevel, setShowSubLevel] = useState(false);

  const addItem = (id: number, isLevel = false) => {
    const value = structuredClone(selected);
    if (value[id] || !isLevel) {
      if (!isLevel) {
        if (value[item?.id]?.has(id)) {
          value[item?.id].delete(id);
        } else {
          if (item.qtySubLevel > value[item?.id].size) value[item?.id].add(id);
        }
      } else {
        isLevel && setShowSubLevel(false);
        delete value[id];
      }
    } else {
      isLevel && setShowSubLevel(true);
      value[id] = new Set();
      setTimeout(() => {
        if (!item.subLevelData && qtyLevel === Object.keys(value).length) {
          moveToNextStep(value);
        }
      }, 100);
    }

    setSelected(value);
  };
  return (
    <>
      <div
        className="border rounded-md py-1 cursor-pointer select-none"
        key={index}
      >
        <div
          className="flex gap-1 items-center px-1.5"
          onClick={() => addItem(item.id, true)}
        >
          <Checkbox
            key={index}
            sx={{ p: 0 }}
            checked={!!selected?.[item.id] || false}
          />
          <p className="flex-1">
            {item.name} [${item.price}]
          </p>
          <div>
            {item?.subLevelData &&
              (showSubLevel ? (
                <KeyboardArrowUpIcon className="!text-[20px]" />
              ) : (
                <KeyboardArrowDownIcon className="!text-[20px]" />
              ))}
          </div>
        </div>
        {item?.subLevelData && (
          <AnimatePresence>
            <motion.div
              className="overflow-hidden"
              initial={{ height: 0 }}
              animate={{ height: showSubLevel ? "auto" : 0 }}
            >
              <div className="border-t mt-1 pt-1">
                {item.subLevelData.map((subItem: any) => (
                  <div
                    className="flex gap-1 items-center ml-8 cursor-pointer"
                    key={index}
                    onClick={() => addItem(subItem?.id)}
                  >
                    <Checkbox
                      key={index}
                      sx={{ p: 0 }}
                      checked={selected?.[item.id]?.has(subItem.id) || false}
                    />
                    <p>
                      {subItem.name} [${subItem.price}]
                    </p>
                  </div>
                ))}
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </>
  );
};
const VariantSelection = ({
  selected,
  setSelected,
  moveToNextStep,
  option = defaultParams,
}: Props) => (
  <div className="px-3 py-2.5 overflow-y-auto flex flex-col gap-1 max-h-[35vh]">
    {option.data.map((item, index) => (
      <Item
        item={item}
        index={index}
        selected={selected}
        setSelected={setSelected}
        qtyLevel={option.qtyLevel}
        moveToNextStep={moveToNextStep}
      />
    ))}
  </div>
);

export default VariantSelection;
