import {
  Dialog,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextareaAutosize,
} from "@mui/material";
import noImage from "assets/images/no-image.png";
import Input from "components/common/Input";
import Select from "components/common/Select";
import api from "config/api";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { addToCart, setSelected } from "store/slices/main.slice";
import { CrustType, SizeType } from "utils/types";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "components/common/Button";
import ModificationPopUp from "./ModificationPopUp";
import VariantSelection from "components/common/VariantSelection";
import { toast } from "react-toastify";

type Props = {};

const initialConfig = {
  qty: 1,
  size: 0,
  crust: 0,
  note: "",
  pizza: "",
  spiceLevel: "Hot",
  modifications: undefined,
  variant: [],
};

const spiceData = ["Hot", "Extra Hot", "Mild", "Med"];

const ProductPopUp = (props: Props) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(undefined);
  const product = useAppSelector(({ main }) => main.selectedProduct);
  const pizzaList = useAppSelector(({ main }) => main.pizzaList);
  const taxes = useAppSelector(({ main }) => main.taxes);
  const dispatch = useAppDispatch();
  const [variants, setVariants] = useState<any>([]);
  const [sizes, setSizes] = useState<SizeType[]>([]);
  const [crusts, setCrusts] = useState<CrustType[]>([]);
  const [config, setConfig] = useState(initialConfig);
  const [showModification, setshowModification] = useState<any>(undefined);
  const [selectedVariantItem, setSelectedVariantItem] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (product?.data) {
      setSelectedProduct(product.data);
      setConfig(product.data.config);
      if (product.data.config.variant) {
        setSelectedVariantItem(product.data.config.variant?.[0] || {});
      }
    } else {
      setSelectedProduct(product);
    }
  }, [product]);

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.isPizza) {
        api
          .get(
            `/api/${
              selectedProduct.isCreateYourOwn
                ? "PizzaToppingPrice"
                : "PizzaSize/ByProductId"
            }?productId=${selectedProduct.productId}`
          )
          .then(({ data }) => {
            if (!selectedProduct?.isEdit) {
              setConfig((e) => ({ ...e, size: data[0].sizeId }));
            }
            setSizes(data);
          })
          .catch(() => {});
      } else {
        api
          .get(
            `/api/Variation/VariantById?productId=${selectedProduct.productId}`
          )
          .then(({ data }) => setVariants(data))
          .catch(() => {});
      }
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedProduct.isPizza && config.size) {
      api
        .get(
          `/api/Modifier/Crusts?sizeId=${config.size}&crustId=0&productId=${selectedProduct.productId}`
        )
        .then(({ data }) => {
          // if (!selectedProduct?.isEdit) {
          setConfig((e) => ({ ...e, crust: data[0].modifierId }));
          // }
          setCrusts(data);
        })
        .catch(toast.error);
    }
  }, [config.size, selectedProduct]);

  const allVariants = useMemo(
    () =>
      variants.reduce((obj: any, { data = [] }: any) => {
        data.forEach((element: any) => {
          obj[element.id] = element;
          element?.subLevelData?.forEach((subElement: any) => {
            obj[subElement.id] = subElement;
          });
        });
        return obj;
      }, {}),
    [variants]
  );

  const addItemToCart = () => {
    toast.dismiss();
    const [tax1, tax2, tax3] = taxes;
    const pizza = pizzaList.find((i) => i.productId === config.pizza);
    const size = sizes.find((i) => i.sizeId === config.size);
    const crust = crusts.find((i) => i.modifierId === config.crust);

    if (!selectedProduct) return;

    const finalProduct: any = {
      data: {},
      id: selectedProduct.productId,
      productId: selectedProduct.productId,
      productCode: selectedProduct.productCode,
      fullDescription: selectedProduct.productName,
      quantity: config.qty,
      price: selectedProduct.price,
      variationsPrice: 0,
      totalUnitPrice: selectedProduct.price,
      subTotal: 0,
      itemTotal: 0,
      taxId1: selectedProduct.taxId1,
      taxId2: selectedProduct.taxId2,
      taxId3: selectedProduct.taxId3,
      note: config.note,
      totalTax1: 0, // update later
      totalTax2: 0, // update later
      totalTax3: 0, // update later
      status: "new",
      pizzaCrust: null,
      pizzaSize: null,
      spiceLevel: null,
      selectedItemsLevel: null,
      modifiers: null,
      isDeal: selectedProduct.isDeal,
      isPizza: selectedProduct.isPizza,
      isCreateYourOwn: selectedProduct.isCreateYourOwn,
    };

    if (product?.data) {
      finalProduct.data = {
        ...product.data,
        config,
        isEdit: true,
        index: product.index,
      };
    } else {
      finalProduct.data = { ...product, config };
    }

    if (
      selectedProduct.isPizza ||
      selectedProduct.isDeal ||
      selectedProduct.isCreateYourOwn
    ) {
      if (!size || !crust) {
        toast.error("Size and Crust are required");
        return;
      } else {
        finalProduct.pizzaCrust = {
          crustId: crust.modifierId,
          crustName: crust.modifierName,
          crustPrice: crust.modifierCost,
        };

        finalProduct.pizzaSize = {
          pizzasizeId: size.sizeId,
          size: size.sizeName,
        };
      }

      if (selectedProduct.isDeal) {
        finalProduct.spiceLevel = {
          spiceId: 1, // update later
          spicename: config.spiceLevel,
        };
      }
    }
    const [baseAMt] = Object.entries(size || {}).reduce(
      ([tk, tv], [key, value]: any) => {
        if (!key.includes("toppingPrice") || !value) return [tk, tv];
        const v = +key.replace("toppingPrice", "");
        if (tk < v) return [v, value];
        return [tk, tv];
      },
      [0, 0]
    );

    if (config?.modifications) {
      finalProduct.modifiers = Object.values(config?.modifications || {})
        .flat()
        .map((item: any, index: number) => ({
          defaultTopping: item.defaultTopping,
          newDefaultTopping: item.newDefaultTopping,
          modifierId: item.pizzaModifierId,
          description: item.pizzaModifierName,
          quantity: 1,
          unitPrice: item.pizzaModifierPrice,
          subTotal: item.pizzaModifierPrice,
          totalValue: item.pizzaModifierPrice,
          note: "",
          isFree: index < baseAMt ? 1 : 0,
          pizzaRegion: 1,
        }));

      const toppingPrice =
        Object.values(config?.modifications || {})
          .flat()
          .filter((i: any) => !i.defaultTopping && !i.newDefaultTopping)
          .slice(baseAMt)
          .reduce((t: number, i: any) => (t += i.pizzaModifierPrice), 0) || 0;

      finalProduct.itemTotal += toppingPrice;
    } else {
      finalProduct.modifiers = selectedProduct.defaultPizzaToppings.map(
        (item: any, index: number) => ({
          defaultTopping: 1,
          newDefaultTopping: 0,
          modifierId: item.modifierId,
          description: item.modifierName,
          quantity: 1,
          unitPrice: 0,
          subTotal: 0,
          totalValue: 0,
          note: "",
          isFree: 1,
          pizzaRegion: 1,
        })
      );
    }
    if ((config?.variant || []).length > 0) {
      const tempVariants: any[] = [];
      config?.variant.forEach((item) => {
        Object.entries(item).forEach(([key, value]: any) => {
          if (allVariants?.[key]) {
            finalProduct.variationsPrice += allVariants?.[key].price;
            tempVariants.push({ ...allVariants[key], isSubLevel: 0 });
          }
          Array.from(value).forEach((subItem: any) => {
            if (allVariants?.[subItem]) {
              finalProduct.variationsPrice += allVariants[subItem].price;
              tempVariants.push({ ...allVariants[subItem], isSubLevel: 1 });
            }
          });
        });
      });

      finalProduct.selectedItemsLevel = tempVariants;
      finalProduct.totalUnitPrice += finalProduct.variationsPrice;
    }
    if (
      selectedProduct &&
      !!selectedProduct.isPizza &&
      !!selectedProduct.isDeal &&
      !selectedProduct.isCreateYourOwn
    ) {
      if (!pizza) {
        toast.error("Pizza item is required");
        return;
      }
    }
    if (selectedProduct.isCreateYourOwn || selectedProduct.isPizza) {
      const newPrice = +(getSizePrice(size) || "0");
      if (newPrice) {
        finalProduct.pizzaSize.price = newPrice;
        finalProduct.price = newPrice;
      }
    }
    finalProduct.itemTotal += finalProduct.price + finalProduct.variationsPrice; // confirm
    finalProduct.subTotal = finalProduct.itemTotal * config.qty;

    if (selectedProduct.taxId1 === 1 && tax1.rate) {
      const total = +((finalProduct.subTotal * tax1.rate) / 100).toFixed(5);
      finalProduct.totalTax1 = total;
      finalProduct.taxRate1 = tax1?.rate ?? 0;
      finalProduct.itemTotal += total;
    } else {
      finalProduct.totalTax1 = 0;
      finalProduct.taxRate1 = 0;
    }
    if (selectedProduct.taxId2 === 2 && tax2.rate) {
      const total = +((finalProduct.subTotal * tax2.rate) / 100).toFixed(5);
      finalProduct.totalTax2 = total;
      finalProduct.taxRate2 = tax2?.rate ?? 0;
      finalProduct.itemTotal += total;
    } else {
      finalProduct.totalTax2 = 0;
      finalProduct.taxRate2 = 0;
    }
    if (selectedProduct.taxId3 === 3 && tax3.rate) {
      const total = +((finalProduct.subTotal * tax3.rate) / 100).toFixed(5);
      finalProduct.totalTax3 = total;
      finalProduct.taxRate3 = tax3?.rate ?? 0;
      finalProduct.itemTotal += total;
    } else {
      finalProduct.totalTax3 = 0;
      finalProduct.taxRate3 = 0;
    }
    dispatch(addToCart(finalProduct));
    onClose();
  };

  const onClose = () => {
    setConfig({ ...initialConfig });
    setCurrentStep(0);
    dispatch(setSelected(undefined));
  };

  const moveToNextStep = (value?: any) => {
    const newConfig: any = structuredClone(config);
    newConfig.variant[currentStep] = value || selectedVariantItem;
    setConfig(newConfig);
    if (product?.data) {
      setSelectedVariantItem(
        product.data.config?.variant?.[currentStep + 1] || {}
      );
    } else {
      const nextVariants: any = {};
      const nextVariantData = variants?.[currentStep + 1];
      if (nextVariantData) {
        if (
          nextVariantData?.mandatoryLevel === 0 &&
          nextVariantData?.data?.length === 1
        ) {
          nextVariants[nextVariantData?.data[0].id] = new Set();
        }
        setSelectedVariantItem(nextVariants);
      } else {
        setSelectedVariantItem({});
      }
    }
    setCurrentStep((e) => e + 1);
  };

  const steps = useMemo(() => {
    if (!selectedProduct || selectedProduct?.isPizza) return [];
    const keys = variants.map((_: any, i: number) => i + 1);
    return [...keys, keys.length + 1];
  }, [selectedProduct, variants]);

  const totalModifications = useMemo(
    () => Object.values(config?.modifications || {}).flat().length,
    [config?.modifications]
  );

  const getSizePrice = useCallback(
    (item: any) => {
      if (selectedProduct?.isCreateYourOwn) {
        if (!totalModifications) return item?.toppingPrice1 || "";
        if (item?.[`toppingPrice${totalModifications}`])
          return item[`toppingPrice${totalModifications}`];
        const [, basePrice] = Object.entries(item || {}).reduce(
          ([tk, tv], [key, value]: any) =>
            (key.includes("toppingPrice") && value) > tv
              ? [+key.replace("toppingPrice", ""), value]
              : [tk, tv],
          [0, 0]
        );
        return basePrice;
      }

      if (selectedProduct?.isPizza) return item.sizePriceX1;
    },
    [selectedProduct, totalModifications]
  );

  const variantStep = useMemo(() => {
    if (!variants?.[currentStep]) {
      return { data: [], mandatoryLevel: 0, qtyLevel: 0 };
    }

    return variants[currentStep];
  }, [variants, currentStep]);

  return (
    <Dialog
      onClose={onClose}
      open={!!selectedProduct}
      maxWidth="lg"
      className="select-none"
    >
      {selectedProduct && (
        <div className="md:min-w-[600px] p-6 flex flex-col gap-3">
          <div className="flex items-center">
            <div className="flex-1 flex justify-center items-center mr-2">
              <img
                src={selectedProduct?.imageUrl || noImage}
                className="w-full max-h-[150px] h-auto object-contain rounded-lg border border-gray-300"
                alt="Product Image"
              />
            </div>
            <div className="flex-[2]">
              <h4 className="font-bold text-[20px]">
                {selectedProduct.productName}
              </h4>
              <p className="text-[15px] capitalize">
                {selectedProduct.productDescription.toLowerCase()}
              </p>
            </div>
          </div>
          {!!selectedProduct.isPizza &&
            !!selectedProduct.isDeal &&
            !selectedProduct.isCreateYourOwn && (
              <Select
                label="Pizza Item"
                value={config.pizza}
                onValueChange={(pizza) => setConfig((v) => ({ ...v, pizza }))}
              >
                {pizzaList.map((item) => (
                  <MenuItem value={item.productId}>{item.productName}</MenuItem>
                ))}
              </Select>
            )}
          {!!selectedProduct.isPizza ? (
            <div className="flex max-md:flex-col gap-3">
              <Select
                label="Size"
                value={config.size}
                onValueChange={(size) =>
                  setConfig((v) => ({ ...v, crust: 0, size }))
                }
              >
                {sizes.map((item) => (
                  <MenuItem value={item.sizeId}>
                    {item.sizeName}{" "}
                    {!!getSizePrice(item) ? `[$${getSizePrice(item)}]` : ""}
                  </MenuItem>
                ))}
              </Select>
              {selectedProduct.isDeal ? (
                <Select
                  label="Spice Level"
                  value={config.spiceLevel}
                  onValueChange={(spiceLevel) =>
                    setConfig((v) => ({ ...v, spiceLevel }))
                  }
                >
                  {spiceData.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  label="Crusts"
                  value={config.crust}
                  onValueChange={(crust) => setConfig((v) => ({ ...v, crust }))}
                >
                  {crusts.map((item) => (
                    <MenuItem value={item.modifierId}>
                      {item.modifierName} (${item.modifierCost})
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          ) : (
            <>
              {steps.length > 1 && (
                <Stepper activeStep={currentStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>Step {label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </>
          )}
          {!selectedProduct.isPizza && !!variants.length && (
            <VariantSelection
              selected={selectedVariantItem}
              setSelected={setSelectedVariantItem}
              moveToNextStep={moveToNextStep}
              option={variantStep}
            />
          )}
          {!!selectedProduct.isPizza || currentStep == steps.length - 1 ? (
            <>
              <TextareaAutosize
                className="border border-[#0000003b] p-2 rounded hover:border-black focus:!ring-primary"
                placeholder="Note..."
                minRows={5}
                value={config.note}
                onChange={(e) =>
                  setConfig((v) => ({ ...v, note: e.target.value }))
                }
              />
              <div className="flex justify-center gap-2">
                <Button
                  className="!px-2"
                  onClick={() =>
                    config.qty > 1 &&
                    setConfig((v) => ({ ...v, qty: v.qty - 1 }))
                  }
                >
                  <RemoveIcon className="!text-light-text" />
                </Button>
                <Input
                  size="small"
                  className="max-w-[80px]"
                  inputProps={{ style: { textAlign: "center" } }}
                  value={config.qty}
                  onValueChange={(value) => {
                    let qty = +value;
                    if (isNaN(qty) || qty < 1) {
                      qty = 1;
                    }
                    setConfig((v) => ({ ...v, qty }));
                  }}
                />
                <Button
                  className="!px-2"
                  onClick={() => setConfig((v) => ({ ...v, qty: v.qty + 1 }))}
                >
                  <AddIcon className="!text-light-text" />
                </Button>
              </div>

              <div className="flex max-sm:flex-col gap-2 justify-between mt-3">
                {steps.length > 1 ? (
                  <Button
                    className="min-h-[45px]"
                    onClick={() => {
                      if (selectedProduct.isPizza) {
                        onClose();
                      } else {
                        setSelectedVariantItem(
                          config.variant?.[currentStep - 1] || {}
                        );
                        setCurrentStep((e) => e - 1);
                      }
                    }}
                  >
                    {selectedProduct.isPizza ? "Cancel" : "Previous"}
                  </Button>
                ) : (
                  <div />
                )}

                {!!selectedProduct.isPizza && (
                  <Button
                    className="min-h-[45px]"
                    onClick={() =>
                      setshowModification({
                        sizeId: config.size,
                        modifications: config.modifications,
                        productId: selectedProduct.productId,
                      })
                    }
                  >
                    Customize [PIZZA]
                  </Button>
                )}

                <Button
                  disabled={
                    !!selectedProduct.isCreateYourOwn &&
                    totalModifications === 0
                  }
                  className="min-h-[45px]"
                  onClick={addItemToCart}
                >
                  {product?.isEdit ? "Update Cart" : "Add to Cart"}
                </Button>
              </div>
            </>
          ) : (
            <div className="flex justify-between items-center  mt-3">
              <Button
                className="min-h-[45px]"
                onClick={() => {
                  if (currentStep === 0) {
                    onClose();
                  } else {
                    setSelectedVariantItem(
                      config.variant?.[currentStep - 1] || {}
                    );
                    setCurrentStep((e) => e - 1);
                  }
                }}
              >
                {currentStep === 0 ? "Cancel" : "Previous"}
              </Button>
              {(Object.keys(selectedVariantItem).length <
                variantStep.mandatoryLevel ||
                Object.keys(selectedVariantItem).length >
                  variantStep.qtyLevel) && (
                <p className="text-red-500">
                  Select Maximun : {variantStep.qtyLevel} and Minimum :
                  {variantStep.mandatoryLevel}
                </p>
              )}
              <Button
                disabled={
                  Object.keys(selectedVariantItem).length <
                    variantStep.mandatoryLevel ||
                  Object.keys(selectedVariantItem).length > variantStep.qtyLevel
                }
                className="min-h-[45px]"
                onClick={() => moveToNextStep()}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      )}
      <ModificationPopUp
        open={showModification}
        setOpen={setshowModification}
        onSubmit={(modifications) => {
          setshowModification(undefined);
          setConfig((v) => ({ ...v, modifications }));
        }}
      />
    </Dialog>
  );
};

export default ProductPopUp;
