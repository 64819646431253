import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Container from "screens";
import { store } from "store";
import { ThemeProvider } from "@mui/material/styles";
import "styles/App.scss";
import "react-toastify/dist/ReactToastify.css";
import { theme } from "themes/mui";
import { ToastContainer } from "react-toastify";

let persistor = persistStore(store);

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Container /> 
            <ToastContainer position="bottom-center" />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
