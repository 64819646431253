import React from "react";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = ({ className, ...rest }: Props) => {
  return (
    <button
      className={`rounded-lg py-1.5 px-5 text-[14px] font-semibold bg-primary text-light-text disabled:bg-primary/40 ${className}`}
      {...rest}
    />
  );
};

export default Button;
