import React from "react";

const Loading = () => {
  return (
    <div>
      <div className={`loading-main`}>
        <div className="loading-outer">
          <div className="container">
            <div style={{ background: "#c1b1e1" }} className="line"></div>
            <div className="line d1" style={{ background: "#4e35b1" }}></div>
            <div style={{ background: "#c1b1e1" }} className="line d2"></div>
            <div className="line d3" style={{ background: "#4e35b1" }}></div>
            <div style={{ background: "#c1b1e1" }} className="line d4"></div>
            <div className="line d5" style={{ background: "#4e35b1" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
