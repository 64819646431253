import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { FormControlLabel, Radio, RadioGroup, Tab, Tabs } from "@mui/material";
import Button from "components/common/Button";
import {
  setGestData,
  setUserData,
  updateCheckoutData,
} from "store/slices/main.slice";
import Input from "components/common/Input";
import api from "config/api";
import { toast } from "react-toastify";
import GoogleAddress from "components/common/GoogleAddress";
import { addMinutes, format, subMinutes } from "date-fns";
import { ORDER, ORDER_NAME } from "utils/constants";

type Props = {
  setStage: (v: string) => void;
};

const initialData = {
  address: "",
  tip: 0,
  clientId: 0,
  suitNo: "",
  buzzerNo: "",
  roomNo: "",
  firstName: "",
  lastName: "",
  address1: "",
  phoneNo: "",
  email: "",
  password: "",
  confirmPassword: "",
  onDate: format(new Date(), "yyyy-MM-dd"),
  onTime: format(new Date(), "HH:mm"),
  selectedAddress: "home",
};

const orderLable: any = {
  TakeOut: ORDER_NAME.TakeOut,
  Delivery: ORDER_NAME.Delivery,
};

const Details = ({ setStage }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [data, setData] = useState(initialData);
  const [tab, setTab] = useState("Login");
  const [initialOnTime, setInitialOnTime] = useState(data.onTime);

  const {
    userData,
    checkoutData,
    validTimes,
    settings,
    companyData,
    gestData,
  } = useAppSelector(({ main }) => main);
  useEffect(() => {
    let updatedData = { ...initialData };

    if (userData && tab === "Login") {
      updatedData = { ...updatedData, ...userData };
    } else if (gestData && tab === "Guest") {
      updatedData = {
        ...updatedData,
        ...gestData,
        onDate: initialData.onDate,
        onTime: initialData.onTime,
      };
    }

    if (checkoutData.selectedAddress) {
      updatedData.selectedAddress = checkoutData.selectedAddress;
    }

    if (
      format(new Date(), "yyyy-MM-dd") === updatedData.onDate ||
      format(new Date(), "yyyy-MM-dd") > updatedData.onDate
    ) {
      let currentTime: Date = new Date();
      const [hours, minutes] = format(currentTime, "HH:mm")
        .split(":")
        .map(Number);
      currentTime.setHours(hours, minutes);

      let updatedTime: Date | undefined;

      if (companyData && checkoutData.orderType === "Delivery") {
        updatedTime = addMinutes(currentTime, companyData.deliveryTime);
      } else if (companyData && checkoutData.orderType === "TakeOut") {
        updatedTime = addMinutes(currentTime, companyData.pickUpTime);
      }

      if (updatedTime) {
        const selectedTime: Date = new Date();
        const [selectedHours, selectedMinutes] = updatedData.onTime
          .split(":")
          .map(Number);
        selectedTime.setHours(selectedHours, selectedMinutes);

        if (selectedTime < updatedTime) {
          updatedData.onTime = format(updatedTime as Date, "HH:mm");
          setInitialOnTime(format(updatedTime as Date, "HH:mm"));
        }
      }
    }

    setData(updatedData);
  }, [userData, gestData, tab, checkoutData.selectedAddress, companyData]);

  const loginUser = (params: any) => {
    const { onDate, onTime } = data;
    setLoading(true);
    api(`/api/Customer/ByIdPass?${new URLSearchParams(params)}`)
      .then(({ data }) => {
        setData((e) => ({ ...e, ...data }));
        dispatch(setUserData(data));
        toast.success("Login Successfully");
        setStage("DETAILS");
        dispatch(updateCheckoutData({ ...data, onDate, onTime }));
      })
      .catch((e) => {
        toast.error("Invalid Email or Password");
      })
      .finally(() => setLoading(false));
  };

  const registerUser = (params: any) => {
    const { onDate, onTime } = data;
    setLoading(true);
    api
      .post(`/api/Customer`, params)
      .then(({ data }) => {
        dispatch(setUserData(data));
        setStage("DETAILS");
        dispatch(updateCheckoutData({ ...data, onDate, onTime }));
        toast.success("User created Successfully");
      })
      .catch((e) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = async () => {
    toast.dismiss();
    let isTimeValid = true;

    if (userData || (data && data.clientId)) {
      if (format(new Date(), "yyyy-MM-dd") === data.onDate) {
        let currentTime: Date = new Date();
        const [hours, minutes] = format(currentTime, "HH:mm")
          .split(":")
          .map(Number);
        currentTime.setHours(hours, minutes);

        let updatedTime: Date | undefined;

        if (companyData && checkoutData.orderType === "Delivery") {
          updatedTime = addMinutes(currentTime, companyData.deliveryTime);
        } else if (companyData && checkoutData.orderType === "TakeOut") {
          updatedTime = addMinutes(currentTime, companyData.pickUpTime);
        }

        if (updatedTime) {
          const selectedTime: Date = new Date();
          const [selectedHours, selectedMinutes] = data.onTime
            .split(":")
            .map(Number);
          selectedTime.setHours(selectedHours, selectedMinutes);

          if (selectedTime < updatedTime) {
            toast.error(
              `The ${
                checkoutData.orderType === "Delivery" ? "delivery" : "takeout"
              } time cannot be before ${format(
                updatedTime,
                "HH:mm"
              )}. Please select a valid time.`
            );
            isTimeValid = false;
            return;
          }
        }
      }
      if (isTimeValid) {
        if (checkoutData.orderType === ORDER.DELIVERY) {
          // Check for at least one address
          if (!data?.address && !data?.address1) {
            toast.error("At least one address is required.");
            return;
          }

          if (!data?.selectedAddress) {
            toast.error("Please select a delivery address.");
            return;
          }

          if (data.selectedAddress === "home" && !data?.address) {
            toast.error("Home address is required.");
            return;
          }

          if (data.selectedAddress === "office" && !data?.address1) {
            toast.error("Office address is required.");
            return;
          }
          if (data.selectedAddress === "home" && data?.address) {
            data.address = data?.address || userData?.address;
          } else if (data.selectedAddress === "office" && data?.address1) {
            data.address1 = data?.address1 || userData?.address1;
          }
        }
        if (userData) {
          const currentUserData = {
            ...userData,
            address: data?.address || "",
            ...(data?.address1 || userData?.address1
              ? { address1: data.address1 || userData.address1 }
              : {}),
          };

          const isAddressSame =
            data?.address === userData?.address &&
            ((data?.address1 &&
              userData?.address1 &&
              data.address1 === userData.address1) ||
              (!data?.address1 && !userData?.address1));

          if (!isAddressSame) {
            api
              .post("/api/Customer/UpdateCustomerData", currentUserData)
              .then(({ data }) => {
                toast.success("User details updated successfully");
                dispatch(setUserData(structuredClone(currentUserData)));
              })
              .catch(() => {
                toast.error("Something went wrong while updating user details");
              });
          }
        }

        setStage("CHECKOUT");
        dispatch(updateCheckoutData(data));
        return;
      }
    }
    const { onDate, onTime } = data;
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        data.email
      )
    ) {
      toast.error("Invalid Email");
      return;
    }

    if (tab === "Login") {
      if (data.password.length <= 3) {
        toast.error("Password must be greater than 3 characters");
        return;
      }
      loginUser({
        emailId: data.email,
        password: data.password,
      });
      return;
    }

    if (!data.firstName || !data.lastName) {
      toast.error("First Name & Last Name are required");
      return;
    }

    if (!/^\d{10}$/.test(data.phoneNo)) {
      toast.error("Invalid Phone Number");
      return;
    }

    if (checkoutData.orderType === ORDER.DELIVERY) {
      if (!data?.address) {
        toast.error("Address is Required");
        return;
      }
    }

    if (tab === "Register") {
      if (data.password.length <= 3) {
        toast.error("Password must be greater than 3 characters");
        return;
      }
      if (data.password !== data.confirmPassword) {
        toast.error("Confirm password does not match");
        return;
      }
      registerUser(data);
      return;
    }

    if (tab === "Guest") {
      dispatch(setGestData(data));

      setLoading(true);
      api
        .get(`/api/Customer/ByPhone?phoneNo=${data.phoneNo}`)
        .then(({ data }) => {
          setStage("DETAILS");
          delete data.address;
          delete data.address1;
          setData((e) => ({ ...e, ...data, onDate, onTime }));
          toast.success("Guest Verified Successfully");
          setLoading(false);
        })
        .catch(() => {
          api
            .post(`api/Customer`, { ...data, password: "123" })
            .then(({ data }) => {
              setStage("DETAILS");
              delete data.address;
              delete data.address1;
              setData((e) => ({ ...e, ...data, onDate, onTime }));
              toast.success("Guest Verified Successfully");
            })
            .catch(() => toast.error("Something went wrong"))
            .finally(() => setLoading(false));
        });
    }
  };
  const checkTimeRange = (
    from: Date | undefined,
    to: Date | undefined,
    hour: number,
    min: number
  ) => {
    let error = false;
    if (!from || !to) return false;
    if (hour < from.getHours() || hour > to.getHours()) {
      error = true;
    }

    if (hour === from.getHours() && min < from.getMinutes()) {
      error = true;
    }

    if (hour === to.getHours() && min > to.getMinutes()) {
      error = true;
    }
    return !error;
  };

  const timeError = useMemo(() => {
    if (!userData) return "";
    console.log("data.onTime.", data.onTime);

    const [hour, min] = data.onTime.split(":");
    const day = format(new Date(data.onDate), "EEEE");

    const found = validTimes.find(
      (i) => i.timDay === day && i.timOrdtype === checkoutData.orderType
    );

    if (!found || found?.timIs24Hr) return "";
    const extraTime =
      (checkoutData.orderType === ORDER.DELIVERY
        ? companyData?.deliveryTime
        : companyData?.pickUpTime) || 0;

    const from = addMinutes(new Date(found.timFrom), extraTime);
    const to = subMinutes(new Date(found.timTo), extraTime);
    const from1 = found.timFrom1
      ? addMinutes(new Date(found.timFrom1), extraTime)
      : undefined;
    const to1 = found.timTo1
      ? subMinutes(new Date(found.timTo1), extraTime)
      : undefined;
    const from2 = found.timFrom2
      ? addMinutes(new Date(found.timFrom2), extraTime)
      : undefined;
    const to2 = found.timTo2
      ? subMinutes(new Date(found.timTo2), extraTime)
      : undefined;

    let error = `${
      ORDER_NAME[checkoutData.orderType]
    } is closed at the moment, you can make this order between `;

    if (from && to) {
      error += `${format(from, "hh:mm aa")} - ${format(to, "hh:mm aa")}`;
    }
    if (from1 && to1) {
      error += `, ${format(from1, "hh:mm aa")} - ${format(to1, "hh:mm aa")}`;
    }
    if (from2 && to2) {
      error += `, ${format(from2, "hh:mm aa")} - ${format(to2, "hh:mm aa")}`;
    }

    error += ` on ${day}`;
    // Check each time range
    const t = checkTimeRange(from, to, +hour, +min);
    const t1 = checkTimeRange(from1, to1, +hour, +min);
    const t2 = checkTimeRange(from2, to2, +hour, +min);

    if (t || t1 || t2) {
      return "";
    }
    return error;
  }, [
    validTimes,
    data.onTime,
    data.onDate,
    checkoutData.orderType,
    companyData,
  ]);
  if (!checkoutData) return <></>;
  return (
    <>
      <div className="flex justify-between items-center px-4 pb-2 border-b">
        <div
          onClick={() => {
            if (tab === "Guest" && data.clientId) {
              setData((e) => ({ ...e, clientId: 0 }));
            } else {
              setStage("CART");
            }
          }}
        >
          <ArrowBackIosNewIcon className="!text-[15px] !text-primary cursor-pointer" />
        </div>
        <h4 className="!text-lg text-primary font-semibold text-center">
          User Details
        </h4>
        <div />
      </div>
      <div className="flex max-sm:flex-col sm:gap-2 items-center border-b px-4 min-h-10 justify-center">
        <p className="font-bold">Order type :</p>
        <p>{orderLable[checkoutData.orderType]}</p>
      </div>
      <div className="px-4">
        {userData || !!data?.clientId ? (
          <div className="flex flex-col pt-3 px-2 md:px-[10%] gap-3">
            <Input
              size="small"
              label="First Name"
              sx={{ flex: 1 }}
              value={data.firstName}
            />
            <Input
              size="small"
              label="Last Name"
              sx={{ flex: 1 }}
              value={data.lastName}
            />
            <Input
              size="small"
              label="Phone No."
              sx={{ flex: 1 }}
              value={data.phoneNo}
            />
            <Input
              size="small"
              label="Email"
              sx={{ flex: 1 }}
              value={data.email}
            />
            <Input
              size="small"
              label="First Name"
              sx={{ flex: 1 }}
              value={data.firstName}
            />
            <Input
              size="small"
              label="Date"
              type="date"
              inputProps={{
                min: format(new Date(), "yyyy-MM-dd"),
                max: settings.AllowFutureDate
                  ? undefined
                  : format(new Date(), "yyyy-MM-dd"),
              }}
              sx={{ flex: 1 }}
              value={data.onDate}
              onValueChange={(onDate) => setData((e) => ({ ...e, onDate }))}
            />
            <Input
              size="small"
              label="Time"
              type="time"
              sx={{ flex: 1 }}
              value={data.onTime}
              onValueChange={(onTime) => setData((e) => ({ ...e, onTime }))}
            />

            {checkoutData.orderType === ORDER.DELIVERY && (
              <div className="border border-[#0000003b] rounded-[4px] relative pt-4 px-4 hover:border-black">
                <p className="absolute left-[10px] -top-[10px] text-[11.5px] px-1 text-[#00000099] bg-white">
                  Delivery Address
                </p>
                <RadioGroup
                  value={data.selectedAddress}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      selectedAddress: e.target.value,
                    }))
                  }
                  name="row-radio-buttons-group"
                >
                  <div className="flex flex-col justify-center mb-2 ">
                    <FormControlLabel
                      value="home"
                      control={<Radio size="small" />}
                      label="Home Address"
                    />
                    <GoogleAddress
                      className="flex-1 ml-2"
                      value={data.address || ""}
                      onChange={(address) =>
                        setData((prev) => ({ ...prev, address }))
                      }
                    />
                  </div>

                  <div className="flex  flex-col justify-center mb-2">
                    <FormControlLabel
                      value="office"
                      control={<Radio size="small" />}
                      label="Office Address"
                    />
                    <GoogleAddress
                      className="flex-1 ml-2"
                      value={data.address1 || ""}
                      onChange={(address1) =>
                        setData((prev) => ({ ...prev, address1 }))
                      }
                    />
                  </div>
                </RadioGroup>
              </div>
            )}
          </div>
        ) : (
          <div className="px-2 md:px-[10%]">
            <div className="flex rounded-md mt-2">
              <div
                className={`flex-1 p-2 cursor-pointer text-center border border-primary transition-all duration-300 rounded-s-lg ${
                  tab === "Login" ? "bg-primary text-light-text" : ""
                }`}
                onClick={() => setTab("Login")}
              >
                Login
              </div>
              <div
                className={`flex-1 p-2 text cursor-pointer text-center border border-primary transition-all duration-300 -mx-[0.3px] ${
                  tab === "Register" ? "bg-primary text-light-text" : ""
                }`}
                onClick={() => setTab("Register")}
              >
                Register
              </div>
              <div
                className={`flex-1 p-2 cursor-pointer text-center border border-primary transition-all duration-300 rounded-e-lg ${
                  tab === "Guest" ? "bg-primary text-light-text" : ""
                }`}
                onClick={() => setTab("Guest")}
              >
                Guest
              </div>
            </div>

            <div className="flex flex-col mt-3 gap-3">
              {tab === "Login" && (
                <>
                  <Input
                    size="small"
                    label="Email"
                    sx={{ flex: 1 }}
                    value={data.email}
                    onValueChange={(email) => setData((e) => ({ ...e, email }))}
                  />
                  <Input
                    size="small"
                    label="Password"
                    type="password"
                    sx={{ flex: 1 }}
                    value={data.password}
                    onValueChange={(password) =>
                      setData((e) => ({ ...e, password }))
                    }
                  />
                </>
              )}
              {tab === "Register" && (
                <>
                  <div className="flex max-sm:flex-col gap-3">
                    <Input
                      size="small"
                      label="First Name"
                      value={data.firstName}
                      sx={{ flex: 1 }}
                      onValueChange={(firstName) =>
                        setData((e) => ({ ...e, firstName }))
                      }
                    />
                    <Input
                      size="small"
                      label="Last Name"
                      value={data.lastName}
                      sx={{ flex: 1 }}
                      onValueChange={(lastName) =>
                        setData((e) => ({ ...e, lastName }))
                      }
                    />
                  </div>
                  <div className="flex max-sm:flex-col gap-3">
                    <Input
                      size="small"
                      label="Phone Number"
                      value={data.phoneNo}
                      sx={{ flex: 1 }}
                      onValueChange={(phoneNo) =>
                        setData((e) => ({ ...e, phoneNo }))
                      }
                    />
                    <Input
                      size="small"
                      label="Email"
                      value={data.email}
                      sx={{ flex: 1 }}
                      onValueChange={(email) =>
                        setData((e) => ({ ...e, email }))
                      }
                    />
                  </div>
                  <div className="flex max-sm:flex-col gap-3">
                    <Input
                      size="small"
                      label="Password"
                      type="password"
                      value={data.password}
                      sx={{ flex: 1 }}
                      onValueChange={(password) =>
                        setData((e) => ({ ...e, password }))
                      }
                    />
                    <Input
                      size="small"
                      label="Confirm Password"
                      type="password"
                      value={data.confirmPassword}
                      sx={{ flex: 1 }}
                      onValueChange={(confirmPassword) =>
                        setData((e) => ({ ...e, confirmPassword }))
                      }
                    />
                  </div>
                  <GoogleAddress
                    className="flex-1"
                    lable="Home Address"
                    value={data.address}
                    onChange={(address) => setData((e) => ({ ...e, address }))}
                  />
                  <GoogleAddress
                    className="col-span-2"
                    lable="Office Address"
                    value={data.address1}
                    onChange={(address1) =>
                      setData((e) => ({ ...e, address1 }))
                    }
                  />
                </>
              )}
              {tab === "Guest" && (
                <>
                  <div className="flex max-sm:flex-col gap-3">
                    <Input
                      size="small"
                      label="First Name"
                      value={data.firstName}
                      sx={{ flex: 1 }}
                      onValueChange={(firstName) =>
                        setData((e) => ({ ...e, firstName }))
                      }
                    />
                    <Input
                      size="small"
                      label="Last Name"
                      value={data.lastName}
                      sx={{ flex: 1 }}
                      onValueChange={(lastName) =>
                        setData((e) => ({ ...e, lastName }))
                      }
                    />
                  </div>
                  <div className="flex max-sm:flex-col gap-3">
                    <Input
                      size="small"
                      label="Phone Number"
                      value={data.phoneNo}
                      sx={{ flex: 1 }}
                      onValueChange={(phoneNo) =>
                        setData((e) => ({ ...e, phoneNo }))
                      }
                    />
                    <Input
                      size="small"
                      label="Email"
                      value={data.email}
                      sx={{ flex: 1 }}
                      onValueChange={(email) =>
                        setData((e) => ({ ...e, email }))
                      }
                    />
                  </div>

                  {checkoutData.orderType === ORDER.DELIVERY && (
                    <GoogleAddress
                      className="flex-1"
                      lable="Address"
                      value={data.address}
                      onChange={(address) =>
                        setData((e) => ({ ...e, address }))
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="border-b w-full mt-2 mb-1 flex-1" />
      {timeError && (
        <div className="flex justify-center">
          <p className="max-w-[430px] text-center text-xs sm:text-sm text-red-500 mx-[5%]">
            {timeError}
          </p>
        </div>
      )}
      <Button
        className="mx-4 mt-1.5"
        onClick={handleSubmit}
        disabled={loading || !!timeError}
      >
        Continue
      </Button>
    </>
  );
};

export default Details;
