import Button from "components/common/Button";
import React, { useEffect, useRef, useState } from "react";
import PayPal from "../payment/PayPal";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Clover from "../payment/Clover";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { placeOrder } from "store/slices/main.slice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

type Props = {
  // amount?: number;
  // setPaymentAmount: (v?: number) => void;
  setStage: (v: string) => void;
};

const PaymentView = ({ setStage }: Props) => {
  const checkoutData = useAppSelector(({ main }) => main.checkoutData);
  const [paymentType, setpaymentType] = useState("pay_pal");
  const dispatch = useAppDispatch();

  if (!checkoutData) return <></>;
  return (
    <div className="w-full">
      <div className="flex justify-between items-center px-4 pb-2 border-b">
        <div onClick={() => setStage("CHECKOUT")}>
          <ArrowBackIosNewIcon className="!text-[15px] !text-primary cursor-pointer" />
        </div>
        <h4 className="!text-lg text-primary font-semibold text-center">
          Make Payment
        </h4>
        <div />
      </div>
      <RadioGroup
        value={paymentType}
        onChange={(e) => setpaymentType(e.target.value)}
        row
        className="flex justify-center mt-2"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="pay_pal"
          control={<Radio size="small" />}
          label="Pay Pal"
        />
        <FormControlLabel
          value="clover"
          control={<Radio size="small" />}
          label="Clover"
        />
      </RadioGroup>
      {paymentType === "pay_pal" && (
        <PayPal
          value={checkoutData.total.toFixed(2)}
          onSubmit={() =>
            dispatch(placeOrder({ paymentType: "oncounter", setStage }))
          }
        />
      )}
      {paymentType === "clover" && (
        <Clover
          value={checkoutData.total}
          onSubmit={() =>
            dispatch(placeOrder({ paymentType: "oncounter", setStage }))
          }
        />
      )}
    </div>
  );

  return <></>;
};

export default PaymentView;
