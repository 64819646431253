import { Checkbox, Dialog, MenuItem } from "@mui/material";
import Button from "components/common/Button";
import Input from "components/common/Input";
import Select from "components/common/Select";
import api from "config/api";
import { addDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TimeItem } from "utils/types";

type DataType = {
  TakeOut: TimeItem[];
  Delivery: TimeItem[];
};

type Props = {
  options: any;
  selected?: TimeItem;
  onClose: (v?: boolean) => void;
};

const TimeSettingDialog = ({ options, onClose, selected }: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TimeItem | undefined>(undefined);

  useEffect(() => {
    if (selected) {
      setData({ ...selected, copyAll: false });
    }
  }, [selected]);

  // const handleSubmit = () => {
  //   if (!data) return;
  //   let { copyAll, ...rest } = structuredClone(data);
  //   const fromDate = new Date(rest.timFrom);
  //   const toDate = new Date(rest.timTo);
  //   toDate.setDate(fromDate.getDate() + +(+toDate < +fromDate));
  //   rest.timTo = format(new Date(toDate), "MM/dd/yyyy hh:mm:ss a");

  //   const fromDate1 = new Date(rest.timFrom1);
  //   const toDate1 = new Date(rest.timTo1);
  //   toDate1.setDate(fromDate1.getDate() + +(+toDate1 < +fromDate1));
  //   rest.timTo1 = format(new Date(toDate1), "MM/dd/yyyy hh:mm:ss a");

  //   const fromDate2 = new Date(rest.timFrom2);
  //   const toDate2 = new Date(rest.timTo2);
  //   toDate2.setDate(fromDate2.getDate() + +(+toDate2 < +fromDate2));
  //   rest.timTo2 = format(new Date(toDate2), "MM/dd/yyyy hh:mm:ss a");

  //   setLoading(true);
  //   let promise: any;
  //   if (copyAll) {
  //     promise = Promise.all(
  //       options[data.timOrdtype].map((i: any) =>
  //         api.post("/api/RestTime/AddResttime", { ...rest, timDay: i.timDay })
  //       )
  //     );
  //   } else {
  //     promise = api.post("/api/RestTime/AddResttime", rest);
  //   }

  //   promise
  //     .then(() => {
  //       toast.success("Timing updated successfully");
  //       onClose(true);
  //     })
  //     .catch(() => toast.error("Something went wrong!"))
  //     .finally(() => setLoading(false));
  // };

  const handleSubmit = () => {
    if (!data) return;

    // Cloning data to avoid mutating state directly
    const { copyAll, ...rest } = structuredClone(data);
    
    // Date calculation logic
    const updateDateField = (dateString: string) => {
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? new Date() : date;
    };

    const fromDate = updateDateField(rest.timFrom);
    const toDate = updateDateField(rest.timTo);
    toDate.setDate(fromDate.getDate() + +(+toDate < +fromDate));
    rest.timTo = format(new Date(toDate), "MM/dd/yyyy hh:mm:ss a");

    const fromDate1 = updateDateField(rest.timFrom1);
    const toDate1 = updateDateField(rest.timTo1);
    toDate1.setDate(fromDate1.getDate() + +(+toDate1 < +fromDate1));
    rest.timTo1 = format(new Date(toDate1), "MM/dd/yyyy hh:mm:ss a");

    const fromDate2 = updateDateField(rest.timFrom2);
    const toDate2 = updateDateField(rest.timTo2);
    toDate2.setDate(fromDate2.getDate() + +(+toDate2 < +fromDate2));
    rest.timTo2 = format(new Date(toDate2), "MM/dd/yyyy hh:mm:ss a");

    setLoading(true);
    let promise: any;
    if (copyAll) {
      promise = Promise.all(
        options[data.timOrdtype].map((i: any) =>
          api.post("/api/RestTime/AddResttime", { ...rest, timDay: i.timDay })
        )
      );
    } else {
      promise = api.post("/api/RestTime/AddResttime", rest);
    }

    promise
      .then(() => {
        toast.success("Timing updated successfully");
        onClose(true);
      })
      .catch(() => toast.error("Something went wrong!"))
      .finally(() => setLoading(false));
  };
 
  if (!data) return <></>;
  return (
    <Dialog onClose={() => onClose()} open={!!selected}>
      <div className="min-w-[80vw] md:min-w-[600px] min-h-[70vh] py-2.5 flex flex-col">
        <h4 className="!text-lg text-primary font-semibold text-center border-b pb-2">
          Update Timing
        </h4>
        <div className="border-b px-4 py-0.5 text-[15px] flex-1 flex flex-col gap-3 pt-3">
          <Select
            label="Day"
            value={data.timDay}
            onValueChange={(timDay) => setData((e: any) => ({ ...e, timDay }))}
          >
            {options[data.timOrdtype].map((item: any) => (
              <MenuItem value={item.timDay}>{item.timDay}</MenuItem>
            ))}
          </Select>
          <Select
            label="Order Type"
            value={data.timOrdtype}
            onValueChange={(timOrdtype) =>
              setData((e: any) => ({ ...e, timOrdtype }))
            }
          >
            {Object.keys(options).map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
          <div className="flex gap-2 w-full">
            <Input
              label="From"
              type="time"
              className="flex-1"
              value={format(new Date(data.timFrom), "HH:mm")}
              onValueChange={(value) => {
                const [h, m] = value.split(":");
                const date = new Date(data.timFrom);
                date.setHours(h);
                date.setMinutes(m);
                setData((e: any) => ({
                  ...e,
                  timFrom: format(date, "MM/dd/yyyy hh:mm:ss a"),
                }));
              }}
            />
            <Input
              label="To"
              type="time"
              className="flex-1"
              value={format(new Date(data.timTo), "HH:mm")}
              onValueChange={(value) => {
                const [h, m] = value.split(":");
                const date = new Date(data.timTo);
                date.setHours(h);
                date.setMinutes(m);
                setData((e: any) => ({
                  ...e,
                  timTo: format(date, "MM/dd/yyyy hh:mm:ss a"),
                }));
              }}
            />
          </div>
          <div className="flex gap-2 w-full">
            <Input
              label="From 1"
              type="time"
              className="flex-1"
              value={format(
                data.timFrom1 ? new Date(data.timFrom1) : new Date(),
                "HH:mm"
              )}
              onValueChange={(value) => {
                const [h, m] = value.split(":");
                const date = data.timFrom1
                  ? new Date(data.timFrom1)
                  : new Date();
                date.setHours(h);
                date.setMinutes(m);
                setData((e: any) => ({
                  ...e,
                  timFrom1: format(date, "MM/dd/yyyy hh:mm:ss a"),
                }));
              }}
            />
            <Input
              label="To 1"
              type="time"
              className="flex-1"
              value={format(
                data.timTo1 ? new Date(data.timTo1) : new Date(),
                "HH:mm"
              )}
              onValueChange={(value) => {
                const [h, m] = value.split(":");
                const date = data.timTo1 ? new Date(data.timTo1) : new Date();
                date.setHours(h);
                date.setMinutes(m);
                setData((e: any) => ({
                  ...e,
                  timTo1: format(date, "MM/dd/yyyy hh:mm:ss a"),
                }));
              }}
            />
          </div>
          <div className="flex gap-2 w-full">
            <Input
              label="From 2"
              type="time"
              className="flex-1"
              value={format(
                data.timFrom2 ? new Date(data.timFrom2) : new Date(),
                "HH:mm"
              )}
              onValueChange={(value) => {
                const [h, m] = value.split(":");
                const date = data.timFrom2
                  ? new Date(data.timFrom2)
                  : new Date();
                date.setHours(h);
                date.setMinutes(m);
                setData((e: any) => ({
                  ...e,
                  timFrom2: format(date, "MM/dd/yyyy hh:mm:ss a"),
                }));
              }}
            />
            <Input
              label="To 2"
              type="time"
              className="flex-1"
              value={format(
                data.timTo2 ? new Date(data.timTo2) : new Date(),
                "HH:mm"
              )}
              onValueChange={(value) => {
                const [h, m] = value.split(":");
                const date = data.timTo2 ? new Date(data.timTo2) : new Date();
                date.setHours(h);
                date.setMinutes(m);
                setData((e: any) => ({
                  ...e,
                  timTo2: format(date, "MM/dd/yyyy hh:mm:ss a"),
                }));
              }}
            />
          </div>
          <div className="flex justify-around">
            <div className="flex items-center">
              <p>Open 24hr :</p>
              <Checkbox
                checked={!!data.timIs24Hr}
                onChange={() =>
                  setData((e: any) => ({
                    ...e,
                    timIs24Hr: +!e.timIs24Hr,
                  }))
                }
              />
            </div>
            <div className="flex items-center">
              <p>Copy All :</p>
              <Checkbox
                checked={!!data.copyAll}
                onChange={() =>
                  setData((e: any) => ({
                    ...e,
                    copyAll: +!e.copyAll,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <Button className="mt-3 mx-3" disabled={loading} onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </Dialog>
  );
};

export default TimeSettingDialog;
